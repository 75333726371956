import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EventService } from './event.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

    profileForm:UntypedFormGroup;

    textTypes = [
        { key : 'key' , value : 'Wedding' },
        { key : 'key1' , value : 'Corporate' },
        { key : 'key2' , value : 'Sociel/Other' },
        { key : 'key3' , value : 'Gala' }
      ]

      eventType = [
        { key : 'key' , value : 'Full Event Catering' },
        { key : 'key1' , value : 'Platters Drop Off or Pick Up (No service)' },
      ]

      submitted = false;

  constructor(private fb: UntypedFormBuilder,
    private eventService: EventService) { }

  ngOnInit(): void {
    this.profileForm = this.fb.group({
        name: ['', Validators.required],
        company: [''],
        email:['',[Validators.required, Validators.email]],
        phone:['',Validators.required],
        eventtype:[''],
        eventdate:[''],
        venueofinterest:[''],
        estimatedguestcount:[''],
        services:['', Validators.required],
        message:['', Validators.required]
      });
  }

  get f() { return this.profileForm.controls; }


  onSubmit() {
    this.submitted = true;
    if (this.profileForm.invalid) {
        return;
    }
    this.eventService.getEventData(this.profileForm.value);
  }

}
