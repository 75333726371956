import { filter, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { MenuListServiceService } from '../components/pages/menu-list-service.service';
import { Router } from '@angular/router';
import { EventEnquiryService } from '../components/pages/event-enquiry/event-enquiry.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ScrollServiceService } from './scroll-service.service';
import { DatePipe } from '@angular/common';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';


@Component({
  selector: 'app-customize-menu',
  templateUrl: './customize-menu.component.html',
  styleUrls: ['./customize-menu.component.scss']
})
export class CustomizeMenuComponent implements OnInit {

  data: any= [];
  desserts: any;
  extrasList: any = [];
  mainsList: any = [];
  soupsList: any;
  startrsList: any = [];
  list:any =[];
  groupArr: any;
  regional_non_veg: any;
  main_curries: any;
  mains_rice: any;
  regional_veg: any;
  biriyani_dishes: any;
  south_india_dish: any;
  mains_noodles: any;
  bread_basket: any;
  family_pack: any;
  kids_meal: any;
  mains_fry: any;
  classic_starters: any;
  soup: any;
  tandoori_starts: any;
  street_tandoori_starts: any;
  extra_kids: any;
  extra_pickle: any;
  extra: any;
  isSticky: boolean = false;
  count:any = 0;
dataList: any;
selection:any = [];
countMains:any = 0;
selection2= [];
selection1= [];
kidsMainsCount: any = 0;
selection4 = [];
selection3 = [];
dessertsCount: any = 0;
extrasCount: any = 0;
isStickyData: boolean;
fres: any;
otherData:boolean = false;
reactiveForm: UntypedFormGroup;
nameinvalidError: boolean = false;
emailinvalid: boolean = false;
phoneinvalid: boolean = false;
messageinvalid: boolean = false;
titleinvalidError: boolean  = false;
serviceTypeError: boolean = false;
title = null;
servicetype = null;
eventduration= null;
newAr1: any =[];
newAr2: any=[];
newAr3: any=[];
newAr4: any=[];
phn = false;
newAr5: any=[];
    eventdurationinvalidError: boolean;
    maxBirthDate: string;
    emailPattern = "^[a-zA-Z0-9_\\+-]+(\\.[a-zA-Z0-9_\\+-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.([a-zA-Z]{2,4})$";
    submitted: boolean;
    now: string;


  @HostListener('window:scroll', ['$event'])

  checkScroll() {
    this.isSticky = window.pageYOffset >= 250;
    this.isStickyData = window.pageYOffset >= 280;
  }

  bgImage = [
    {
        img: 'assets/img/register-shape.jpg'
    }
]
constructor(private http: HttpClient,
            private menuListServiceService:MenuListServiceService,
            private router: Router,
            private eventService: EventEnquiryService,
                private fb: UntypedFormBuilder,
                private scrollService:ScrollServiceService) {}

ngOnInit(): void {
    let auxDate = this.substractYearsToDate(new Date( ), 100);
    this.maxBirthDate = this.getDateFormateForSearch(auxDate);
    const datePipe = new DatePipe('en-Us');
     this.now = datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.reactiveForm = this.fb.group({
        title: [null,Validators.required],
        name:['',Validators.required],
        email:['',[Validators.required,Validators.email,Validators.pattern(this.emailPattern)]],
        phone:['',Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
        servicetype:['',Validators.required],
        othercatering:[''],
        occasiondescription:[''],
        eventdate:[''],
        eventstarttime:[''],
        eventduration:[''],
        numberofguests:[''],
        venueinmind:[''],
        message:['',Validators.required]
    });

    this.menuListServiceService.getmenudata().subscribe(res =>{
        this.list = res;
        this.regional_non_veg = this.list.filter(x => x.menu === "Regional_Non_Veg");
        this.main_curries = this.list.filter(x => x.menu === "Mains_Curries");
        this.mains_rice = this.list.filter(x => x.menu === "Mains_Rice");
        this.regional_veg = this.list.filter(x => x.menu === "Regional_Veg");
        this.biriyani_dishes= this.list.filter(x => x.menu === "Biryani_Dishes");
        this.south_india_dish = this.list.filter(x => x.menu === "South_Indian_Dishes");
        this.mains_noodles = this.list.filter(x => x.menu === "Mains_Noodles");
        this.bread_basket = this.list.filter(x => x.menu === "Bread_Basket");
        this.family_pack = this.list.filter(x => x.menu === "Family_Pack_Biryani");
        this.kids_meal = this.list.filter(x => x.menu === "Kids_Meal");
        this.mains_fry = this.list.filter(x => x.menu === "Mains_Fry");
        this.groupArr = this.list.reduce((r,{menu})=>{
          if(!r.some(o=>o.menu==menu)){
            r.push({menu,groupItem:this.list.filter(v=>v.menu==menu)});
      }
      return r;
      },[]);
    })

    this.getStarters();
    this.getDesserts();
    this.getmenu();
    this.getSoups();
}


getStarters(){
 this.menuListServiceService.getStartersmenu().subscribe(res=>{
  this.list = res;
  this.startrsList = this.list.filter(x => x.menu === "Starters");
  this.classic_starters = this.list.filter(x => x.menu === "Classic_Starters");
  this.tandoori_starts = this.list.filter(x => x.menu === "Tandoori_Starters");
  this.street_tandoori_starts = this.list.filter(x => x.menu === "Street_Food_Starters");
 })
}

getDesserts(){
  this.menuListServiceService.getdesserts().subscribe(res=>{
      this.list = res;
      this.desserts = this.list.filter(x => x.menu === "Desserts");
  })
}

getmenu() {
  this.menuListServiceService.getExtraList().subscribe(res=>{
      this.list = res;
      this.extra_kids = this.list.filter(x => x.menu === "Extras_Kids");
      this.extra_pickle = this.list.filter(x => x.menu === "Extras_Pickles");
      this.extra = this.list.filter(x => x.menu === "Extras");
  })
}

getSoups(){
  this.menuListServiceService.getSoupList().subscribe(res=>{
      this.list = res;
      this.soupsList = this.list.filter(x => x.menu === "Soup");
  })
}

 getSelection(item) {
    return this.selection.findIndex(s => s.id === item.id) !== -1;
  }

  changeHandler(item: any, event: KeyboardEvent) {
    const id = item.id;
    const index = this.selection.findIndex(u => u.id === id);
    if (index === -1) {
      this.selection = [...this.selection, item];
      this.count = this.selection.length;
    } else {
      this.selection = this.selection.filter(user => user.id !== item.id);
      this.count = this.selection.length;
    }
  }

  mainsGetSelection(item) {
    return this.selection1.findIndex(s => s.id === item.id) !== -1;
  }

  mainsChangeHandler(item: any, event: KeyboardEvent) {
    const id = item.id;
    const index = this.selection1.findIndex(u => u.id === id);
    if (index === -1) {
      this.selection1 = [...this.selection1, item];
      this.countMains = this.selection1.length;

    } else {
      this.selection1 = this.selection1.filter(user => user.id !== item.id)
      this.countMains = this.selection1.length;
    }
  }

  dessertsGetSelection(item) {
    return this.selection3.findIndex(s => s.id === item.id) !== -1;
  }

  dessertsChangeHandler(item: any, event: KeyboardEvent) {
    const id = item.id;
    const index = this.selection3.findIndex(u => u.id === id);
    if (index === -1) {
      this.selection3 = [...this.selection3, item];
      this.dessertsCount = this.selection3.length;

    } else {
      this.selection3 = this.selection3.filter(user => user.id !== item.id)
      this.dessertsCount = this.selection3.length;
    }
  }
  kidsmealGetSelection(item) {
    return this.selection4.findIndex(s => s.id === item.id) !== -1;
  }

  kidsmealChangeHandler(item: any, event: KeyboardEvent) {
    const id = item.id;
    const index = this.selection4.findIndex(u => u.id === id);
    if (index === -1) {
      this.selection4 = [...this.selection4, item];
      this.kidsMainsCount = this.selection4.length;

    } else {
      this.selection4 = this.selection4.filter(user => user.id !== item.id)
      this.kidsMainsCount = this.selection4.length;
    }
  }

  extrasGetSelection(item) {
    return this.selection2.findIndex(s => s.id === item.id) !== -1;
  }

  extrasChangeHandler(item: any, event: KeyboardEvent) {
    const id = item.id;
    const index = this.selection2.findIndex(u => u.id === id);
    if (index === -1) {
      this.selection2 = [...this.selection2, item];
      this.extrasCount = this.selection2.length;

    } else {
      this.selection2 = this.selection2.filter(user => user.id !== item.id)
      this.extrasCount = this.selection2.length;
    }
  }

//   save() {
//         this.startrsList = this.selection.filter(x => x.menu === "Starters");
//         this.classic_starters = this.selection.filter(x => x.menu === "Classic_Starters")
//         this.fres= this.selection.concat(this.selection1,this.selection2,this.selection3,this.selection4);

//     }

    scrollToTop(): void {
    window.scrollTo(0, 0);
    }

       nameinvalid() {
        if(this.reactiveForm.get('name').value == "" || this.reactiveForm.get('name').value == undefined){
            this.nameinvalidError = true;
        } else {
            this.nameinvalidError = false;
        }
    }

    substractYearsToDate(auxDate: Date, years: number): Date {
        auxDate.setFullYear(auxDate.getFullYear() + years);
        return auxDate;
      }

      getDateFormateForSearch(date: Date): string {
        let year = date.toLocaleDateString('es', { year: 'numeric' });
        let month = date.toLocaleDateString('es', { month: '2-digit' });
        let day = date.toLocaleDateString('es', { day: '2-digit' });
        return `${year}-${month}-${day}`;
      }

      get f() { return this.reactiveForm.controls; }


    emailinvalidError() {
        // const data1 = this.reactiveForm.get('email').value;
        // this.http.post('https://ihcv59cjhk.execute-api.eu-west-2.amazonaws.com/dev/verifyemail',{email:data1}).subscribe(res=>{
        //     const data = res['valid_email'];

        //     if(data == "no") {
        //         this.emailinvalid = true;
        //     } else {
        //         this.emailinvalid = false;
        //     }
        // })
        if(this.reactiveForm.get('email').value == "" || this.reactiveForm.get('email').value == undefined){
            this.emailinvalid = true;
            this.submitted = true;
        } else {
            this.emailinvalid = false;
            this.submitted = true;
        }
    }

    phoneinvalidError() {
        if(this.reactiveForm.get('phone').value == "" || this.reactiveForm.get('phone').value == undefined){
            this.phoneinvalid = true;
            this.phn = true;
        } else {
            this.phoneinvalid = false;
            this.phn = true;
        }
    }

    messageinvalidError() {
        if(this.reactiveForm.get('message').value == "" || this.reactiveForm.get('message').value == undefined){
            this.messageinvalid = true;
        } else {
            this.messageinvalid = false;
        }
    }

    titleinvalid() {
        if(this.reactiveForm.get('title').value == "" || this.reactiveForm.get('title').value == undefined){
            this.titleinvalidError = true;
        } else {
            this.titleinvalidError = false;
        }
    }

    eventdurationinvalid() {
        if(this.reactiveForm.get('eventduration').value == "" || this.reactiveForm.get('eventduration').value == undefined){
            this.eventdurationinvalidError = true;
        } else {
            this.eventdurationinvalidError = false;
        }
    }

    serviceTypeinvalid() {
        if(this.reactiveForm.get('servicetype').value == "" || this.reactiveForm.get('servicetype').value == undefined || this.reactiveForm.get('servicetype').value == null ){
            this.serviceTypeError = true;
        } else {
            this.serviceTypeError = false;
        }
    }

    dataReset() {
        this.reactiveForm.reset();
    }

    submitData(){


        this.newAr1 = this.selection.map((item, index, arr) => {
            return item.name ;
          })
          this.newAr2 = this.selection1.map((item, index, arr) => {
            return item.name;
          })
          this.newAr3 = this.selection2.map((item, index, arr) => {
            return item.name;
          })
          this.newAr4 = this.selection3.map((item, index, arr) => {
            return item.name;
          })
          this.newAr5 = this.selection4.map((item, index, arr) => {
            return item.name;
          })


          if(this.reactiveForm.invalid) {
            this.nameinvalid();
            this.emailinvalidError();
            this.phoneinvalidError();
            this.messageinvalidError();
            this.titleinvalid();
            this.serviceTypeinvalid();
            // this.eventdurationinvalid();
            console.log("error");
        } else {
            console.log("Success");
            this.submitted=false;
            console.log(this.reactiveForm.value);
            const data = {
                "Starters":this.newAr1,
                "Mains":this.newAr2,
                "Desserts":this.newAr4,
                "Extras":this.newAr3,
                "Kids_Meal":this.newAr5
        }

        let results = this.reactiveForm.value;
        results.menu = data;
        results.eventstarttime = this.reactiveForm.get("eventstarttime").value + this.reactiveForm.get("eventduration").value;
            this.eventService.cusomiseMenuData(this.reactiveForm.value);
            this.router.navigateByUrl('/thank_you');
        }
    }

    onChange(deviceValue) {
        if(deviceValue == "Other") {
            this.otherData =true;
        } else {
            this.otherData = false;
        }
    }
    scrollToId(id: string) {
        this.scrollService.scrollToElementById(id);
    }
    separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
	phoneForm = new UntypedFormGroup({
		phone: new UntypedFormControl(undefined, [Validators.required])
	});
    get pf() { return this.phoneForm.controls; }
	changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}
}
