<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Allergy Advice</li>
            </ul>
            <h2>Allergy Advice</h2>
        </div>
    </div>
    <
</div>

<div class="terms-of-service-area ptb-100">
    <div class="container">
        <h3>Allergens</h3>
        <p>Please note that many of the items on our menu will contain one or more of the allergens listed below. If you have an allergy to any of these, please state your requirements clearly in the cooking instructions box provided in the Options section of the item before adding to your order, or in the Comments box when going through the checkout. Alternatively, before ordering, please speak to our staff about your requirements on 020 8550 7777. There are 14 major allergens which need to be declared when used as ingredients. The following list tells you what these allergens are and provides some examples of foods where they may be found:</p>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/milk.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Milk</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This is found in butter, cheese, cream, milk powders and yoghurt. It is often used in foods glazed with milk, powdered soups and sauces.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/eggs.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Eggs</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This is often found in cakes, some meat products, mayonnaise, mousses, pasta, quiche, sauces and foods brushed or glazed with egg.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/gluten.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Gluten</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This includes wheat (such as spelt and Khorasan wheat/Kamut), rye, barley and oats. It is often found in foods containing flour, such as some baking powders, batter, breadcrumbs, bread, cakes, couscous, meat products, pasta, pastry, sauces, soups and foods dusted with flour.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/soya.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Soya</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This can be found in beancurd, edamame beans, miso paste, textured soya protein, soya flour or tofu. It is often used in some desserts, ice cream, meat products, sauces and vegetarian products.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/peanuts.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Peanuts</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This can be found in biscuits, cakes, curries, desserts and sauces such as for satay. It is also found in groundnut oil and peanut flour.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/nuts.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Nuts</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This includes almonds, hazelnuts, walnuts, cashews, pecan nuts, Brazil nuts, pistachio nuts, macadamia or Queensland nuts. These can be found in breads, biscuits, crackers, desserts, ice cream, marzipan (almond paste), nut oils and sauces. Ground, crushed or flaked almonds are often used in Asian dishes such as curries or stir fries</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/lupin.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Lupin</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This includes lupin seeds and flour, and can be found in some types of bread, pastries and pasta.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/celery.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Celery</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This includes celery stalks, leaves and seeds and celeriac. It is often found in celery salt, salads, some meat products, soups and stock cubes.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/mustard.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Mustard</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This includes liquid mustard, mustard powder and mustard seeds. It is often found in breads, curries, marinades, meat products, salad dressing, sauces and soups.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/fish.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Fish</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This is often found in some fish sauces, pizzas, relishes, salad dressings, stock cubes and in Worcestershire sauce.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/shellfish.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Crustaceans</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This includes crabs, lobster, prawns and scampi. It is often found in shrimp paste used in Thai curries or salads.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/molluscs.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Molluscs</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This includes mussels, land snails, squid and whelks. It is often found in oyster sauce or as an ingredient in fish stews.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/so2.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Sulphur Dioxide</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This is often used as a preservative in dried fruit, meat products, soft drinks and vegetables as well as in wine and beer.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-12 text-center">
                <img src="../../../../assets/img/allergies/sesame.png" alt="image">
            </div>
            <div class="col-lg-2 col-md-12">
                <h3 class="mt-4">Sesame Seeds</h3>
            </div>
            <div class="col-lg-8 col-md-12">
                <p class="mt-4">This can be found in bread, breadsticks, houmous, sesame oil and tahini (sesame paste).</p>
            </div>
        </div>
    </div>
</div>
