<div class="boxes-area bg-f5f7fa pt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6 col-md-6" routerLink="/catering-services">
                <div class="single-box-item box-background">
             <!--        <div class="icon">
                        <i class="flaticon-brain-process"></i>
                        <img src="assets/img/icon-shape.png" alt="image">
                    </div>
                    <h3>Learn the Latest Skills</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/membership-levels" class="link-btn">Start Now!</a> -->
                    <h3>Our Catering Services</h3>
                    <img src="assets/img/icon-shape-iac.png" alt="image">
                    <p>Our catering services truly are unique. We specialise in authentic Indian cuisine and cater to all events.</p>
                    <a routerLink="/catering-services" class="link-btn" style="color: blue;">View More Details  <i class="flaticon-right-chevron"></i></a>

                    <p> <img src="assets/img/pot-iac.png" alt="image"></p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6"  routerLink="/reviews">
                <div class="single-box-item box-background">
                   <!--  <div class="icon">
                        <i class="flaticon-shield-1"></i>
                        <img src="assets/img/icon-shape.png" alt="image">
                    </div>
                    <h3>Learn from Industry Experts</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/membership-levels" class="link-btn">Start Now!</a> -->
                    <h3>Reviews</h3>
                    <img src="assets/img/icon-shape-iac.png" alt="image">
                    <p>We take our job and service standards very seriously and care about your pleasure. We are thrilled to have received such positive comments!</p>
                    <a routerLink="/reviews" class="link-btn" style="color: blue;">View More Details  <i class="flaticon-right-chevron"></i></a>

                    <p> <img src="assets/img/pot-iac.png" alt="image"></p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6"  routerLink="/menus">
                <div class="single-box-item box-background">
                 <!--    <div class="icon">
                        <i class="flaticon-computer"></i>
                        <img src="assets/img/icon-shape.png" alt="image">
                    </div>
                    <h3>Go at Your Own Pace</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/membership-levels" class="link-btn">Start Now!</a> -->
                    <h3>Menus</h3>
                    <img src="assets/img/icon-shape-iac.png" alt="image">
                    <p>We have beautiful menus for all kinds of events and making sure everyone has a good time makes us feel good.</p>
                    <a routerLink="/menus" class="link-btn" style="color: blue;">View More Details  <i class="flaticon-right-chevron"></i></a>

                    <p> <img src="assets/img/pot-iac.png" alt="image"></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6"  routerLink="/our-gallery">
                <div class="single-box-item box-background">
                   <!--  <div class="icon">
                        <i class="flaticon-shield-1"></i>
                        <img src="assets/img/icon-shape.png" alt="image">
                    </div>
                    <h3>Learn from Industry Experts</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/membership-levels" class="link-btn">Start Now!</a> -->
                    <h3>Gallery</h3>
                    <img src="assets/img/icon-shape-iac.png" alt="image">
                    <p>You may view some of the lovely catering services and party meals we've supplied to our clients here.</p>
                    <a routerLink="/our-gallery" class="link-btn" style="color: blue;">View More Details  <i class="flaticon-right-chevron"></i></a>

                    <p> <img src="assets/img/pot-iac.png" alt="image"></p>
                </div>
            </div>


        </div>
    </div>
</div>
