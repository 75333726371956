<app-distance-banner></app-distance-banner>
<app-distance-boxes></app-distance-boxes>
<app-funfacts-style-two></app-funfacts-style-two>
<app-awards></app-awards>
<app-order></app-order>
<app-distance-about></app-distance-about>
<app-distance-popular-courses></app-distance-popular-courses>
<app-choice-of-menus></app-choice-of-menus>
<app-feedback></app-feedback>
<div class="premium-access-area bg-f9f9f9 ptb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div>
<app-subscribe></app-subscribe>
