<footer class="footer-area">
    <div class="container">
        <div class="row">
             <!--    /* added custom radsun (BEGIN) */ -->
             <div class="social-right" style="z-index: 1;">
                <a href="https://wa.me/+447916507777" target="_blank">
                    <img src="assets/img/65x65/whatsapp.svg" class="rounded-img" alt="INDIAN AROMA CATERERS">
                 </a>
                <a href="https://www.facebook.com/IndianAromaGantsHill/" target="_blank">
                    <img src="assets/img/65x65/facebook.svg" class="rounded-img" alt="INDIAN AROMA CATERERS">
                </a>
                <a href="https://www.instagram.com/indian_aroma_london/" target="_blank">
                    <img src="assets/img/65x65/insta.svg" class="ml-1 rounded-img" alt="INDIAN AROMA CATERERS">
                </a>
                <a href="https://www.tripadvisor.co.uk/Restaurant_Review-g580420-d10331967-Reviews-Indian_Aroma_Restaurant-Ilford_Greater_London_England.html" target="_blank">
                    <img src="assets/img/65x65/trip.svg"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
                </a>
                <a href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" target="_blank">
                    <img src="assets/img/65x65/youtube.svg"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
                </a>
                <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9?source=g.page.m.we._" target="_blank">
                    <img src="assets/img/65x65/google.svg"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
                </a>
                <a href="https://twitter.com/IndianAroma_LDN" target="_blank">
                    <img src="assets/img/65x65/twitter.svg"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
                </a>
                <a href="https://www.tiktok.com/@indian.aroma01" target="_blank">
                    <img src="assets/img/65x65/Tiktok.svg"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
                </a>
                <a href="https://www.pinterest.co.uk/indianaromalondon/" target="_blank">
                    <img src="assets/img/65x65/pinterest.svg"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
                </a>
                <a href="https://www.linkedin.com/company/indian-aroma-cuisine-limited/" target="_blank">
                    <img src="assets/img/linkdin.png"  class="iconresponse rounded-img" alt="INDIAN AROMA CATERERS">
                </a>
            </div>
                      <!--    /* added custom radsun (END) */ -->
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="../../../../assets/img/logonh.svg" alt="logo" height="150"></a>
                    <p>Our catering services are very unique. We provide a truly authentic Indian food for all occasions. We have an excellent team of trained chefs and staff members who will work with you in understanding your requirements, giving you options and delivering the best Indian food.</p>
                    <ul class="social-link" >
                        <li><a href="https://wa.me/+447916507777" class="d-block" target="_blank"><i class="bx bxl-whatsapp"></i></a></li>
                        <li><a href="https://www.facebook.com/IndianAromaGantsHill/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/indian_aroma_london/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.tripadvisor.co.uk/Restaurant_Review-g580420-d10331967-Reviews-Indian_Aroma_Restaurant-Ilford_Greater_London_England.html" class="d-block" target="_blank"><i class="fa fa-tripadvisor" style="font-size:14px"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" class="d-block" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        <li><a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9?source=g.page.m.we._" class="d-block" target="_blank"><i class="bx bxl-google"></i></a></li>
                        <li><a href="https://twitter.com/IndianAroma_LDN" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="https://www.pinterest.co.uk/indianaromalondon/" class="d-block" target="_blank"><i class="bx bxl-pinterest"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/indian-aroma-cuisine-limited/" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://www.tiktok.com/@indian.aroma01" style="display: table-cell;" target="_blank"><img src="assets/img/65x65/Vector.svg" alt="INDIAN AROMA CATERERS" style="height: 18px;"></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Popular</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/reviews">Reviews</a></li>
                        <li><a routerLink="/menus">Menus</a></li>
                        <li><a routerLink="/catering-services">Catering Services</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                        <li><a routerLink="/our-gallery">Gallery</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Resources</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/sitemap">Sitemap</a></li>
                        <li><a routerLink="/allergy-advice" >Allergy Advice</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/cookie-policy">Cookie Policy</a></li>
                        <li><a routerLink="/terms-of-service">Terms & Conditions</a></li>
                        <li><a routerLink="/about-us">About Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>
                            <a href="https://goo.gl/maps/CoVWU4kZ7HioBDJMA" target="_blank">33 Woodford Avenue, Gants Hill, IG2 6UF, UK</a>
                            </li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+442085507777">020 8550 7777</a></li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+447916507777">079 1650 7777</a></li>
                        <!-- <li><i class="bx bx-credit-card"></i><a href="https://wa.me/+447916507777">079 1650 7777</a></li> -->
                        <li><i class="fa fa-whatsapp"></i><a href="https://wa.me/+447916507777">+44 79 16507777</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:contact@indianaromacateres.co.uk">contact&#64;indianaromacaterers.co.uk</a></li>
                        <li class="pl-0"><img src="assets/img/ecofr.png" alt="INDIAN AROMA CATERERS" class="image-footer"> <img src="assets/img/foodhygine.png" alt="INDIAN AROMA CATERERS" class="image-footer"> <img src="assets/img/googlereview.png" alt="INDIAN AROMA CATERERS" class="image-footer"></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">

                    <p style="float: left;font-size: xx-small;">
                        Copyright &#64;2022 All rights reserved by <a href="https://www.indianaromacaterers.co.uk/" target="_blank">Indian Aroma Cuisine Limited</a>

                        | Site Developed by <a target="_blank" href="https://www.radsuntech.com/">RAD SUN TECHNOLOGY LIMITED</a></p>
                </div>
                <div class="col-lg-6 col-md-6" style="float: left;font-size: xx-small;">
                    <ul>
                        <li><a routerLink="/allergy-advice" >Allergy Advice</a></li>
                        <li><a routerLink="/cookie-policy" >Cookie Policy</a></li>
                        <li><a routerLink="/privacy-policy" >Privacy Policy</a></li>
                        <li><a routerLink="/terms-of-service">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines" >
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
