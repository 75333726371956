<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Terms & Conditions</li>
            </ul>
            <h2>Terms & Conditions</h2>
        </div>
    </div>
    <
</div>

<div class="terms-of-service-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="terms-of-service-content">
                    <h2>Terms & Conditions</h2>
                    <h3>1. Registration</h3>
                    <blockquote class="blockquote">
                        <p>Please register an account with us in order to place your order online.</p>
                        <p>On the registration page you will be first asked to enter your postcode. Our Postcode Finder will tell you if our delivery covers your postcode.</p>
                        <li>By placing an order through our site, you warrant that:
                            <p>(a) you are legally capable of entering into binding contracts; and (b) you are at least 18 years old.</p>
                        </li> </blockquote>
                    <h3>2. Payment</h3>
                    <ol>
                        <li>Collection Orders:
                            <p>Pay instore on collection by cash or credit / debit card.</p>
                            <p>Pay online securely by credit / debit card.</p>
                        </li>
                        <li>Delivery Orders:
                            <p>Pay our driver by cash only (please note: drivers carry limited change so if you are paying by cash, try to have the correct amount ready).</p>
                            <p>Pay online securely by credit / debit card.</p>
                        </li>
                    </ol>

                    <h3>3. Products</h3>
                    <p>All products are subject to availability</p>
                    <p>Some products contain nuts – if in doubt please contact us through our Contact Page.</p>
                    <p>Although great care has been taken to remove all bones from meat, some may remain.</p>

                    <h3>4.Consent</h3>
                    <p>By submitting your information you consent to the use of that information as set out in our Privacy Policy Statement.</p>

                    <h3>5. Order Restrictions</h3>
                    <p>Online Orders for delivery will only be accepted from addresses with postcodes which fall within our delivery area as determined by our postcode finder (subject to a minimum delivery order value – please refer to our Delivery Charges).</p>
                    <p>Customers whose addresses are further than 5 miles but less than 100 miles away may still make an Online Order which must be collected from our restaurant and paid for in full on collection.</p>
                    <p>All customers may choose to call and collect their orders instore. Note: uncollected orders at the close of business will be disposed of but still the charged for.</p>
                    <p>Online Orders may only be placed during the Online Ordering Service opening times which are clearly displayed on our Menu pages.</p>

                    <h3>6. Cancellations</h3>
                    <p>You have the right to cancel an order up to 10 minutes from the time you have placed the order.</p>
                    <p>Customers can cancel an order by telephoning the restaurant. Our telephone number can be found on our <a href="/contact">Contact Page</a>.</p>
                    <p>Any cancellations after 10 minutest of placing the order will be classified as a “Bad Order” and will be charged to the customer, and no refund will be given.</p>
                    <p>Any orders cancelled in accordance with the above cancellation provisions will not be charged.</p>
                    <p>Any payment made prior to an order being cancelled within the specified time will be reimbursed using the same method as used by the customer to make the payment.</p>

                    <h3>7. Our Liability</h3>
                    <p>To the extent permitted by law, Indian Aroma provides this site and content on an “as-is” and “as available” basis and makes no representation or warranty of any kind, express or implied, regarding the content or availability of our site, or that it will be timely or error-free, that defects will be corrected, or that our site or server that makes it available are free of viruses or other harmful components.</p>
                    <p>Our liability for losses you suffer as a result of us breaking this contract, including deliberate breaches, is strictly limited to the purchase price of the Product(s) you order.</p>

                    <h3>8. Termination</h3>
                    <p>We reserve the right to decline a new registration, terminate your right to link to our site, remove you as a user of our site, and/or prevent any further use if you breach any of your obligations under these terms and conditions.</p>
                    <p>If we terminate your right to link to our site you must cease linking to our site immediately. We reserve the right to assign or sub-contract any or all of our rights and obligations under these terms.</p>

                    <h3>9. Communication</h3>
                    <p>Applicable laws require that some of the information or communications we send to you should be in writing. When using our site, you accept that communication with us will be mainly electronic. We will contact you by e-mail or provide you with information by posting notices on our website.</p>
                    <p>For contractual purposes, you agree to this electronic means of communication and you acknowledge that all contracts, notices, information and other communications that we provide to you electronically comply with any legal requirement that such communications be in writing.</p>
                    <p>This does not affect your statutory rights.</p>

                    <h3>10. And finally...</h3>
                    <p>We reserve the right to revise and amend these terms and conditions from time to time.</p>
                    <p>You will be subject to the policies and terms and conditions in force at the time that you order Product(s) from Indian Aroma, unless any change to those policies or these terms and conditions is required to be made by law or governmental authority (in which case it will apply to orders previously placed by you), or if we notify you of the change to those policies or these terms and conditions before you are presented with the Confirmation Screen.</p>
                    <p>We also reserve the right to alter, amend or withdraw prices and special offers without notice, although any changes will not affect orders which have gone through the Confirmation Screen.</p>
                    <p>Contracts for the purchase of products through this site and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) will be governed by English law. Any dispute or claim arising out of or in connection with such contracts or their formation (including non-contractual disputes or claims) shall be subject to the non-exclusive jurisdiction of the courts of England and Wales.</p>
                    <p>If you have any questions relating to these terms and conditions please&nbsp;<a href="/contact">contact us</a>&nbsp;before you place an order. If you do not accept these terms and condition please exit our site.</p>
                </div>
            </div>
        </div>
    </div>
</div>
