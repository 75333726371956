import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, InjectionToken, OnInit } from "@angular/core";
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";



@Component({
    selector:'app-blogdetails',
    templateUrl:'./blogdetails.component.html',
    styleUrls:['./blogdetails.component.scss']
})

export class blogdetailsComponent implements OnInit{

    submitted : boolean
        blogDetail: any = [];
  blogForm:UntypedFormGroup;
    list: Object;
    blog_comments: any;
    nameinvalidError: boolean;
    emailinvalid: boolean;
    pid: any;
    blogDetailer: any;
    postBodydata: any =[];
    validatingForm: UntypedFormGroup;
    reply:UntypedFormGroup;
    closeResult = '';
    commentId:any;
    comment_reply:Object;
    object : any
    emailPattern = "^[a-zA-Z0-9_\\+-]+(\\.[a-zA-Z0-9_\\+-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.([a-zA-Z]{2,4})$";
    rnameinvalidError: boolean;
    remailinvalid: boolean;
    rsubmitted: boolean;
    commentinvalid: boolean;

  constructor(private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router : Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {


    this.blogForm = this.fb.group({
        name:['',Validators.required],
        email:['',[Validators.required,Validators.email,Validators.pattern(this.emailPattern)]],

        commentContent:['']


    });
    this.reply=this.fb.group({
        name:[''],
        email:[''],

        commentReply:['']
    })






    this.activatedRoute.queryParams.subscribe(params => {
        this.pid = params['id'];
         let queryParams = new HttpParams();
        queryParams = queryParams.append("id",this.pid);

    this.http.get('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/blog/post', {params:queryParams} ).subscribe(res=>{

        const blogData = res;
        this.blogDetailer = blogData;
       this.blog_comments = this.blogDetailer.blog_comments;
       this.blogDetail = this.blogDetailer.blog_details;
       this.postBodydata = this.blogDetail.postContent;
        // console.log(this.jsobj(1));
        console.log()
        return res;
    })

    });


  }
 cid(crid){
    this.commentId=crid;
    return this.commentId;
 }
  open(content) {
    this.modalService.open(content,
   {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult =
         `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  jsobj(object){
    // for(i in this.blog_comments[i].commentReplies){
        // this.object=this.blog_comments[i].commentReplies
  this.comment_reply= Object.keys(object).map(e=>object[e]);
    // console.log(this.comment_reply);
    // }

  return this.comment_reply;

}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  nameinvalid() {
    if(this.blogForm.get('name').value == "" || this.blogForm.get('name').value == undefined){
            this.nameinvalidError = true;
        } else {
            this.nameinvalidError = false;
        }
    }
    get f() { return this.blogForm.controls; }
    emailinvalidError() {
        if(this.blogForm.get('email').value == "" || this.blogForm.get('email').value == undefined ){
            this.emailinvalid = true;
            this.submitted = true;

        } else {
            this.emailinvalid = false;
            this.submitted = true;

        }
    }
    replynameinvalid() {
        if(this.reply.get('name').value == "" || this.reply.get('name').value == undefined){
                this.rnameinvalidError = true;
            } else {
                this.rnameinvalidError = false;
            }
        }
        get rf() { return this.reply.controls; }
        replyCommentInvalid(){
            if(this.reply.get('commentReply').value=="" || this.reply.get('commentReply').value == undefined){
                this.commentinvalid=true;
            }
            else{
                this.commentinvalid=false;
            }
        }
        replyemailinvalidError() {
            if(this.reply.get('email').value == "" || this.reply.get('email').value == undefined ){
                this.remailinvalid = true;
                this.rsubmitted = true;

            } else {
                this.remailinvalid = false;
                this.rsubmitted = true;

            }
        }

    submitReplyData() {
        for(const rep in this.blog_comments[0].commentReplies){
        }
        console.log(this.reply.value);
        console.log(this.commentId);
        if(this.reply.invalid) {
            this.replynameinvalid();
            this.replyemailinvalidError();
            this.replyCommentInvalid();
            this.reply.invalid;
        }
        else{

            const replyData=
            {
                "commentAuthor": {
                  "name": this.reply.get('name').value,
                  "email": this.reply.get('email').value,

                },
                "commentContent": this.reply.get('commentReply').value,
                "commentId": this.commentId,
                "postId": this.pid
             }
        this.http.post('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/blog/post/comment/reply',replyData).subscribe(res =>{
            this.ngOnInit();
          return res;
      })
      this.modalService.dismissAll();
        this.reply.reset();
        }

    }


    submitData() {

        console.log(this.blogForm.value);
        if(this.blogForm.invalid) {
            this.nameinvalid();
            this.emailinvalidError();
            this.blogForm.invalid;
        } else {
            this.submitted=false;
            const data = {
                "postId":this.pid,
                "commentContent":this.blogForm.get('commentContent').value,
                "commentAuthor":  {
                    "name":this.blogForm.get('name').value,
                    "email": this.blogForm.get('email').value,

                }
           }
            this.http.post('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/blog/post/comment',data).subscribe(res =>{
                this.ngOnInit();
              return res;
          })
          this.blogForm.reset();
        }
    }
}
