<div class="subscribe-content ptb-100">
    <span class="sub-title">INDIAN AROMA CATERERS</span>
    <h2>Subscribe To Our Newsletter</h2>
    <form class="newsletter-form" [formGroup]="reactiveForm">
        <div class="form-group">
            <input name="email" type="email" formControlName="email" (keyup)="emailinvalidError()"
            class="form-control"
            required="required"
            placeholder="Email*"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div class="alert alert-danger" *ngIf="submitted && f.email.errors ">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
        </div>
        <button type="submit" class="default-btn" (click)="dataPass()"><i class="flaticon-user"></i>Subscribe Now<span></span></button>
    </form>
</div>
