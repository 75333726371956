import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  list : any;
  pageOfItems: Array<any>;
  q:any;
  searchText: string;

  constructor(private http:HttpClient,
    private router : Router) { }

  ngOnInit(): void {
    this.http.get('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/blog').subscribe(res=>{
      const data=res;
      this.list=data;
      this.list=this.list.content

    })
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  getData(m){
    this.router.navigate(['/blogdetails'],{queryParams:{id:m}});
  }
}
