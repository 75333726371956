<div class="container">
    <div class="section-title">
        <span class="sub-title">Education for everyone</span>
        <h2>Affordable Online Courses and Learning Opportunities​</h2>
        <p>Finding your own space and utilize better learning options can result in faster than the traditional ways. Enjoy the beauty of eLearning!</p>
    </div>
    <div class="row">
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-brain-process"></i>
                </div>
                <h3>Learn the Latest Top Skills</h3>
                <p>Learning top skills can bring an extra-ordinary outcome in a career.</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-computer"></i>
                </div>
                <h3>Learn in Your Own Pace</h3>
                <p>Everyone prefers to enjoy learning at their own pace & that gives a great result.</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-shield-1"></i>
                </div>
                <h3>Learn From Industry Experts</h3>
                <p>Experienced teachers can assist in learning faster with their best approaches!</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-world"></i>
                </div>
                <h3>Enjoy Learning From Anywhere</h3>
                <p>We are delighted to give you options to enjoy learning from anywhere in the world.</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
            </div>
        </div>
    </div>
</div>