import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-veg-menu',
  templateUrl: './veg-menu.component.html',
  styleUrls: ['./veg-menu.component.scss']
})
export class VegMenuComponent implements OnInit {
    @HostListener('window:scroll', ['$event'])
    
    checkScroll() {
      this.isSticky = window.pageYOffset >= 250;
    }
    isSticky: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

}
