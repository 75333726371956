<div class="container">
    <div class="section-title">
        <span class="sub-title">News and Blogs</span>
        <p>This blog's articles are centred on the most recent events and Indian Aroma Caters-related news. We constantly update this website so that our clients may see how we are progressing and the recognitions we are receiving.</p>
        <h2>Our Latest Publications</h2>
        <p>News, opinions, features, and other information of general interest are published here on a daily, weekly or other regular basis. This would provide our clients a heads up about the events they wish to attend, such as charity events we manage, market stalls we set up, festivals we cater for, etc., so they can plan their participation or contact us for further information to get involved.</p>
    </div>

    <div class="search_tab">
        <div class="form-outline mb-4">
            <input type="search" [(ngModel)]="searchText" class="form-control" id="datatable-search-input" placeholder="Search by blog title or keyword">
        </div>
    </div>
     <div>

    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6" *ngFor="let item of list | searchFilter: searchText  | paginate: { itemsPerPage: 6,currentPage: q }">
            <div class="single-blog-post">
                <div class="post-image">
                    <a (click)="getData(item.id)" class="d-block">
                        <img src={{item.postImageUrl}} alt="image" class="blogtile">
                    </a>
                </div>
                <div class="post-content">
                    <a (click)="getData(item.id)" class="category"><i class='flaticon-calendar'></i> {{item.postDate | date}}</a>
                    <div class="post-author d-flex align-items-center">
                        <!-- <img src="assets/img/user1.png" class="rounded-circle" alt="image"> -->
                        <span>by {{item.postAuthor}}</span>
                    </div>
                    <div></div>
                    <h3 class="postTitle"><a (click)="getData(item.id)">{{item.postTitle}}</a></h3>
                    <!-- <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>

                        </li>
                    </ul> -->
                </div>

            </div>
        </div>


        <pagination-controls (pageChange)="q = $event" class="pagine"></pagination-controls>


        <!-- <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="post-image">
                    <a routerLink="/single-blog-1" class="d-block">
                        <img src="assets/img/blog/img2.jpg" alt="image">
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blog-1" class="category">Online</a>
                    <h3><a routerLink="/single-blog-1">Online Learning Can Prepare Students For A Fast-Changing</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                <span>Sarah Taylor</span>
                            </div>
                        </li>
                        <li><i class='flaticon-calendar'></i> April 29, 2020</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-blog-post">
                <div class="post-image">
                    <a routerLink="/single-blog-1" class="d-block">
                        <img src="assets/img/blog/img3.jpg" alt="image">
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blog-1" class="category">Learning</a>
                    <h3><a routerLink="/single-blog-1">As Learning Moves Online, Trigger Warnings Must Too</a></h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                <span>David Warner</span>
                            </div>
                        </li>
                        <li><i class='flaticon-calendar'></i> April 28, 2020</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12">
            <div class="blog-post-info">
                <p>Get into details now?​ <a routerLink="/blog-1">View all posts</a></p>
            </div>
        </div> -->
    </div>
</div>
