
import { HttpClient,HttpParams  } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
    submitted: boolean
    emailId:string;
    reactiveForm: any;
    emailinvalid: boolean = false;
    emailPattern = "^[a-zA-Z0-9_\\+-]+(\\.[a-zA-Z0-9_\\+-]+)*@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.([a-zA-Z]{2,4})$";
    reportdata: any;

  constructor( private http: HttpClient,
               private router: Router,
               private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.reactiveForm = this.fb.group({
        email:['',[Validators.required,Validators.email,Validators.pattern(this.emailPattern)]],
    });
  }

  emailinvalidError() {
    // const data1 = this.reactiveForm.get('email').value;
    // this.http.post('https://ihcv59cjhk.execute-api.eu-west-2.amazonaws.com/dev/verifyemail',{email:data1}).subscribe(res=>{
    //     const data = res['valid_email'];

    //     if(data == "no") {
    //         this.emailinvalid = true;
    //     } else {
    //         this.emailinvalid = false;
    //     }
    // })
    if(this.reactiveForm.get('email').value == "" || this.reactiveForm.get('email').value == undefined){
        this.emailinvalid = true;
        this.submitted = true;

    } else {
        this.emailinvalid = false;
        this.submitted = true;

        }
    }

    get f() { return this.reactiveForm.controls; }
  dataPass(){
    if(this.reactiveForm.invalid) {
    this.emailinvalidError();
    } else{
        this.submitted=false;
        const data1 =this.reactiveForm.value;

          this.http.post("https://vt8cp662fg.execute-api.eu-west-2.amazonaws.com/test/newsletter/ses/subscribe",data1).subscribe(res=>{

            const data:any = res;
            this.reportdata= data.Response;
            this.router.navigate( ['thank_you_newsletter'], { queryParams: { jwt: this.reportdata}});
          })

    }

  }

}
