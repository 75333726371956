<div class="row m-0 pb-5">
    <div class="col-lg-6 col-md-12">
        <h3 class="text-center">Sample image here</h3>
    </div>

    <div class="col-lg-6 col-md-12">
        <form class="login-form" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <div class="row p-0">
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label>Name<span class="gfield_required ">*</span></label>
                    <input type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" >
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Name is required</div>
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label>Company</label>
                    <input type="text" class="form-control" formControlName="company">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label>Email<span class="gfield_required ">*</span></label>
                    <input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label>Phone<span class="gfield_required ">*</span></label>
                    <input type="text" class="form-control" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">Mobile Number is required.</div>
                        <div *ngIf="f.phone.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label>Event Type</label>
                    <select class="form-select form-control" formControlName="eventtype">
                        <option value="" disabled selected>Select your option</option>
                        <option *ngFor="let item of textTypes"
                                [value]="item.value">{{item.value}}</option>
                    </select>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label>Event Type</label>
                    <input type="date" class="form-control" formControlName="eventdate">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label>Venue Of Interest</label>
                    <input type="text" class="form-control" formControlName="venueofinterest">
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label>Estimated Guest Count</label>
                    <input type="text" class="form-control" formControlName="estimatedguestcount">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label>What Services Are You Interested In?<span class="gfield_required ">*</span></label>
                    <select class="form-select form-control" formControlName="services" [ngClass]="{ 'is-invalid': submitted && f.services.errors }">
                        <option value="" disabled selected>----</option>
                            <option *ngFor="let item of eventType"
                                        [value]="item.value">{{item.value}}</option>
                    </select>
                    <div *ngIf="submitted && f.services.errors" class="invalid-feedback">
                        <div *ngIf="f.services.errors.required">The Field is required</div>
                    </div>
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <!-- <label>CAPTCHA</label>
                    <div class="g-recaptcha" data-sitekey="6Ldbdg0TAAAAAI7KAf72Q6uagbWzWecTeBWmrCpJ"></div> -->
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <label>Comments / Message<span class="gfield_required ">*</span></label>
                    <textarea class="form-control" rows="5" formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                    <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                        <div *ngIf="f.message.errors.required">The Field is required</div>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                    <label>Captcha</label>
                    <div class="g-recaptcha" data-sitekey="6LdU1XgeAAAAAA33weoYispyWuKEH1niRZ2KsatH"></div>
                </div>
            </div> -->
            <button type="submit">SEND MESSAGE</button>
        </form>
    </div>
</div>
