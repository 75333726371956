<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
               <li>Audits & Certificates</li>
            </ul>
             <h2>Audits & Certificates</h2>
         </div>
     </div>
     <
 </div>

 <div class="privacy-policy-area ptb-100">
     <div class="container">
         <div class="row">
             <div class="col-lg-12 col-md-12">
                 <div class="privacy-policy-content">
                     <h3>Important Information</h3>
 <p>                    We are perfectionists with a desire to give unrivalled service and quality, and we are proud to be a well-known and well-respected company in the Asian catering sector.
 </p>
 <p>                    Our menus feature high-quality cuisine, and we take pleasure in using only the freshest ingredients and the highest-quality food to guarantee that your guests have a memorable experience. Traditional recipes are reimagined with a trendy and modern touch and presented in a contemporary manner.</p>

                     <p>All of our employees are extensively trained and certified in food hygiene, food safety, and health and safety, and we use a HACCP-based system that is fully documented.</p>

                    <p>We follow a stringent food safety and hygiene policy at Indian Aroma, ensuring quality from raw material sourcing to storage, preparation, cooking, and transportation.</p>

                     <p>All of our employees are well-trained, and we stay up to speed on the latest food safety regulations. Our cutting-edge catering facilities have been approved and recognised as food manufacturing facilities.</p>


             </div>

         </div>
     </div>
 </div>
