<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Gallery</li>
            </ul>
            <h2>Our Gallery</h2>
        </div>
    </div>
    <
</div>

<app-gallery-services-block></app-gallery-services-block>
