import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { MenuListServiceService } from '../menu-list-service.service';

@Component({
  selector: 'app-veg-menu-list',
  templateUrl: './veg-menu-list.component.html',
  styleUrls: ['./veg-menu-list.component.scss'],
  providers:[MenuListServiceService]
})
export class VegMenuListComponent implements OnInit {
    @HostListener('window:scroll', ['$event'])

    checkScroll() {
      this.isSticky = window.pageYOffset >= 250;
    }

    list: any = [];
    isSticky: boolean = false;
    groupArr: any;
    main_curries: any;
    mains_rice: any;
    regional_veg: any;
    biriyani_dishes: any;
    south_india_dish: any;
    mains_noodles: any;
    bread_basket: any;
    family_pack: any;
    kids_meal: any;
    mains_fry: any;
    starters: any;
    desserts: any;
    extras: any;
    extra_kids: any;
    extra_pickles: any;
    classic_starters: any;
    soup: any;
    tandoori_starts: any;
    street_tandoori_starts: any;

  constructor(private http: HttpClient,
             private menuListServiceService:MenuListServiceService) { }

  ngOnInit(): void {
    this.menuListServiceService.getvegList().subscribe(res=>{
        this.list = res;

        this.starters = this.list.filter(x => x.menu === "Starters");
        this.main_curries = this.list.filter(x => x.menu === "Mains_Curries");
        this.mains_rice = this.list.filter(x => x.menu === "Mains_Rice");
        this.regional_veg = this.list.filter(x => x.menu === "Regional_Veg");
        this.biriyani_dishes= this.list.filter(x => x.menu === "Biryani_Dishes");
        this.south_india_dish = this.list.filter(x => x.menu === "South_Indian_Dishes");
        this.mains_noodles = this.list.filter(x => x.menu === "Mains_Noodles");
        this.bread_basket = this.list.filter(x => x.menu === "Bread_Basket");
        this.family_pack = this.list.filter(x => x.menu === "Family_Pack_Biryani");
        this.kids_meal = this.list.filter(x => x.menu === "Kids_Meal");
        this.mains_fry = this.list.filter(x => x.menu === "Mains_Fry");
        this.desserts = this.list.filter(x => x.menu === "Desserts");
        this.extras = this.list.filter(x => x.menu === "Extras");
        this.extra_kids = this.list.filter(x => x.menu === "Extras_Kids");
        this.extra_pickles = this.list.filter(x => x.menu === "Extras_Pickles");
        this.classic_starters = this.list.filter(x => x.menu === "Classic_Starters");
        this.soup = this.list.filter(x => x.menu === "Soup");
        this.tandoori_starts = this.list.filter(x => x.menu === "Tandoori_Starters");
        this.street_tandoori_starts = this.list.filter(x => x.menu === "Street_Food_Starters");
        this.desserts = this.list.filter(x => x.menu === "Desserts");


          this.groupArr = this.list.reduce((r,{menu})=>{
            if(!r.some(o=>o.menu==menu)){
              r.push({menu,groupItem:this.list.filter(v=>v.menu==menu)});
        }
        return r;
        },[]);
    });
  }


  scrollToTop(): void {
    window.scrollTo(0, 0);
    }
    
}
