<div class="page-title-area">
    <div class="container">
       <div class="page-title-content">
          <ul>
             <li><a routerLink="/">Home</a></li>
             <li>Customise Menu</li>
          </ul>
          <h2>Customise Menu</h2>
          <br/>
          <p><i class='bx bx-food-menu'></i> &nbsp;We have a rich selection of <a routerLink="/pure-vegmenu">vegetarian menus</a>. We can also accommdate your <a routerLink="/allergy-advice" tooltip="Spice: Low | medium | High;
             V: Suitable for Vegeterians;
             Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
             placement="right">unique dietary needs and spice preferences</a>, if you could let us know in advance.</p>.
          <p> <i class='bx bx-envelope'></i> &nbsp;Please
             <a routerLink="/event-enquiry">contact us</a>
             to discuss your event so we can provide an appropriate price for you.
          </p>
       </div>
    </div>
    <
 </div>
 <div class="container" id="desktop">
    <div class="container-fluid"style="text-align: center;background-color: white;">
        <div class="single-funfacts">
            <p><a class="default-btn" (click)="scrollToId('elId')"><i class="flaticon-heart"></i>Select Menu  Type & Get a Free Quote</a></p>
        </div>
    </div>
     <div class="row">
       <div class="col-lg-3">
          <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
             <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Starters<span class="badge bg-secondary">{{count}}</span></a>
             <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Mains<span class="badge bg-secondary">{{countMains}}</span></a>
             <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">Extras<span class="badge bg-secondary">{{extrasCount}}</span></a>
             <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#data2" role="tab" aria-controls="v-pills-settings" aria-selected="false">Desserts<span class="badge bg-secondary">{{dessertsCount}}</span></a>
             <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#data6" role="tab" aria-controls="v-pills-settings" aria-selected="false">Kids Meal<span class="badge bg-secondary">{{kidsMainsCount}}</span></a>
          </div>
       </div>
       <div class="col-lg-9">
          <div class="tab-content" id="v-pills-tabContent">
             <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                <div class="container-fluid" style="display: flex;flex-wrap: wrap;">
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Starters</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of startrsList" class="list-group-item">
                            <input type="checkbox" [checked]="getSelection(value.name)"  value="{{value.name}}"  (change)="changeHandler(value, $event)"> <img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Classic Starters</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of classic_starters" class="list-group-item">
                            <input type="checkbox" [checked]="getSelection(value.name)"  value="{{value.name}}"  (change)="changeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Street Food Starters</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of street_tandoori_starts" class="list-group-item">
                            <input type="checkbox" [checked]="getSelection(value.name)"  value="{{value.name}}"  (change)="changeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Tandoori Starters</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of tandoori_starts" class="list-group-item">
                            <input type="checkbox" [checked]="getSelection(value.name)"  value="{{value.name}}"  (change)="changeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Soup</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of soupsList" class="list-group-item">
                            <input type="checkbox" [checked]="getSelection(value.name)"  value="{{value.name}}"  (change)="changeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                </div>
             </div>
             <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div class="container-fluid" style="display: flex;flex-wrap: wrap;">
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Mains curries</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of main_curries" class="list-group-item">
                            <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Regional Non Veg</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of regional_non_veg" class="list-group-item">
                            <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Mains Rice</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of mains_rice" class="list-group-item">
                            <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Biriyani Dishes</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of biriyani_dishes" class="list-group-item">
                            <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Family Pack Biryani</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of family_pack" class="list-group-item">
                            <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Bread Basket</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of bread_basket" class="list-group-item">
                            <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Regional Veg</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of regional_veg" class="list-group-item">
                            <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Mains Fry</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of mains_fry" class="list-group-item">
                            <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Mains Noodles</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of mains_noodles" class="list-group-item">
                            <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">South Indian Dishes</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of south_india_dish" class="list-group-item">
                            <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                </div>
             </div>
             <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div class="container-fluid" style="display: flex;flex-wrap: wrap;">
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Extras Kids</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of extra_kids" class="list-group-item">
                            <input type="checkbox" [checked]="extrasGetSelection(value)" value="{{value.name}}"  (change)="extrasChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Extras Pickles</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of extra_pickle" class="list-group-item">
                            <input type="checkbox" [checked]="extrasGetSelection(value)" value="{{value.name}}"  (change)="extrasChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Extras</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of extra" class="list-group-item">
                            <input type="checkbox" [checked]="extrasGetSelection(value)" value="{{value.name}}"  (change)="extrasChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         </li>
                      </ul>
                   </div>
                </div>
             </div>
             <div class="tab-pane fade" id="data2" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <h4 class="ml-3">Desserts</h4>
                <ul class="list-group list-group-hover list-group-striped">
                   <li *ngFor="let value of desserts" class="list-group-item">
                    <input type="checkbox" [checked]="dessertsGetSelection(value)" value="{{value.name}}"  (change)="dessertsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                      <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                         tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                         placement="right">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                      {{value.allergens}}
                      </span>
                      </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                   </li>
                </ul>
             </div>
             <div class="tab-pane fade" id="data6" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <h4 class="ml-3">Kids Meal</h4>
                <ul class="list-group list-group-hover list-group-striped">
                   <li *ngFor="let value of kids_meal" class="list-group-item">
                    <input type="checkbox" [checked]="kidsmealGetSelection(value)" value="{{value.name}}"  (change)="kidsmealChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                      <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                         tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                         placement="right">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                      {{value.allergens}}
                      </span>
                      </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                   </li>
                </ul>
             </div>
          </div>
       </div>
    </div>
    <!--Customize Menu form-->

    <div class="col-lg-12 col-md-12"  id="elId">
        <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
            <h2>Get a Quote
            </h2>
            <p>Please fill out the following online form and a member of our team will call you as soon as possible to discuss the Food Menu and any other requirements.</p>
            <form [formGroup]="reactiveForm">
                <div class="row">
                    <div class="col-lg-12 col-md-6">
                        <div class="form-group">
                            <select formControlName="title" [(ngModel)]="title" (click)="titleinvalid()" class="form-select" id="title">
                                <option [ngValue]="null" disabled selected>Select Title*</option>
                                <option>Mr</option>
                                <option>Mrs</option>
                                <option>Miss</option>
                                <option>Ms</option>
                                <option>Dr</option>
                            </select>
                            <div class="alert alert-danger" *ngIf="titleinvalidError">Title is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-6">
                        <div class="form-group">
                            <input formControlName="name" class="form-control" (keyup)="nameinvalid()" placeholder="Your Full Name*">
                            <div class="alert alert-danger" *ngIf="nameinvalidError">
                                <div>Full Name is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-6">
                        <div class="form-group">
                         <input name="email" type="email" formControlName="email" (keyup)="emailinvalidError()" class="form-control" placeholder="Email*"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                             <div class="alert alert-danger" *ngIf="submitted && f.email.errors ">
                                 <div *ngIf="f.email.errors.required">Email is required</div>
                                 <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                             </div>
                     </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                     <div class="form-group">
                         <!-- <input name="phone" type="text" (keyup)="phoneinvalidError()" formControlName="phone" class="form-control" placeholder="Phone*"> -->
                         <ngx-intl-tel-input
                         [cssClass]="'custom'"
                         [preferredCountries]="preferredCountries"
                         [customPlaceholder]="'phone*'"
                         [enableAutoCountrySelect]="true"
                         [enablePlaceholder]="true"
                         [searchCountryFlag]="true"
                         [searchCountryField]="[
                           SearchCountryField.Iso2,
                           SearchCountryField.Name
                         ]"
                         [selectFirstCountry]="false"
                         [selectedCountryISO]="CountryISO.UnitedKingdom"
                         [maxLength]="15"
                         [phoneValidation]="true"
                         [separateDialCode]="separateDialCode"
                         [numberFormat]="PhoneNumberFormat.National"
                         name="phone"
                         (keyup)="phoneinvalidError()"
                         formControlName="phone"
                       >
                       </ngx-intl-tel-input>
                       <div class="alert alert-danger" *ngIf=" phn && f.phone.invalid "  >
                         <div *ngIf="f.phone.errors.required">Phone is required</div>
                         <div *ngIf="f.phone.invalid">Enter a valid Phone Number</div>
                         </div>
                     </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <select name="servicetype" [(ngModel)]="servicetype" (click)="serviceTypeinvalid()" formControlName="servicetype" class="form-select" (change)="onChange($event.target.value)">
                                <option  [ngValue]="null"  disabled selected>Choose Catering Occasion*</option>
                                <!-- <option>Live Dosa Station</option> -->
                                <option>Family Gathering</option>
                                <option>Festival & Social Events</option>
                                <option>Weddings</option>
                                <option>Film Crew Catering</option>
                                <option>Corporate Events</option>
                                <option>Sports Events</option>
                                <option>Charity Fundraising Events</option>
                                 <option>Other</option></select>
                            <div class="alert alert-danger" *ngIf="serviceTypeError">Catering Occasion is required.</div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12" *ngIf="otherData">
                        <div class="form-group">
                            <input name="occasiondescription" type="text" formControlName="occasiondescription" class="form-control" placeholder="Enter Occasion Description">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input name="eventdate" type="date" formControlName="eventdate" [min]="now" [max]="maxBirthDate" class="form-control"  placeholder="Occasion Date  (dd-mm-yyyy) - Select Date">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <input name="eventstarttime" type="time" formControlName="eventstarttime"  class="form-control" placeholder="Occasion Start Time">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <select formControlName="eventduration" [(ngModel)]="eventduration" class="form-select" id="eventduration">
                         <option [ngValue]="null"  disabled selected>Choose AM/ PM</option>
                         <option>PM</option>
                         <option>AM</option>
                        </select>
                        <!-- <div class="alert alert-danger" *ngIf="eventdurationinvalidError">Duration is required.</div> -->
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input name="numberofguests" type="text" formControlName="numberofguests"  class="form-control" placeholder="Guest Number (Estimate)">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input name="venueinmind" type="text" class="form-control" formControlName="venueinmind" placeholder="Venue Name/Address ">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea formControlName="message" (keyup)="messageinvalidError()" name="message" cols="30" rows="5" class="form-control" placeholder="Tell us about your catering requirements Or any food allergies...*"></textarea>
                            <div class="alert alert-danger" *ngIf="messageinvalid">Message is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label class="form-check-label text-white" for="flexCheckDefault" >
                                By sending this form, I confirm that I have read and accept the
                                <a routerLink="/terms-of-service">Terms</a> and <a routerLink="/privacy-policy">Privacy Policy.</a>
                            </label>
                          </div>

                    </div>
                    <div class="col-lg-12 col-md-12 d-flex">
                        <button type="submit" class="default-btn" (click)="submitData()">Send Message<span></span></button>
                        <!-- <button class="default-btn" (click)="dataReset()">Reset<span></span></button> -->
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!--Customized menu form Ends-->
 </div>

 <div class="container-fluid p-0">
    <div class="mt-3 mobile-mode">
        <div class="container-fluid" [ngClass]="{'mat-elevation-z5' : true, 'stickyData' : isStickyData}" style="text-align: center;background-color: white;">
            <div class="single-funfacts" (click)="scrollToId('getquoate')">
                <p><a class="default-btn"><i class="flaticon-heart"></i>Select Menu  Type & Get a Free Quote</a></p>
            </div>
        </div>
        <ul class="nav nav-tabs fixed-top" role="tablist" [ngClass]="{'mat-elevation-z5' : true, 'sticky' : isSticky}">
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#mobile1" role="tab" aria-controls="v-pills-home" aria-selected="true">Starters<span class="badge bg-secondary">{{count}}</span></a>
            </li>
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#mobile2" role="tab" aria-controls="v-pills-profile" aria-selected="false">Mains<span class="badge bg-secondary">{{countMains}}</span></a>
            </li>
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#mobile3" role="tab" aria-controls="v-pills-settings" aria-selected="false">Extras<span class="badge bg-secondary">{{extrasCount}}</span></a>
            </li>
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#mobile4" role="tab" aria-controls="v-pills-settings" aria-selected="false">Desserts<span class="badge bg-secondary">{{dessertsCount}}</span></a>
            </li>
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#mobile5" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                    Kids Meal<span class="badge bg-secondary">{{kidsMainsCount}}</span></a>
            </li>
          </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div id="mobile1" class="container tab-pane active"><br>
            <div class="container-fluid" style="display: flex;flex-wrap: wrap;">
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Starters</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of startrsList" class="list-group-item">
                        <input type="checkbox" [checked]="getSelection(value.name)"  value="{{value.name}}"  (change)="changeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Classic Starters</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of classic_starters" class="list-group-item">
                        <input type="checkbox" [checked]="getSelection(value)" value="{{value.name}}"  (change)="changeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Street Food Starters</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of street_tandoori_starts" class="list-group-item">
                        <input type="checkbox" [checked]="getSelection(value)" value="{{value.name}}"  (change)="changeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Tandoori Starters</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of tandoori_starts" class="list-group-item">
                        <input type="checkbox" [checked]="getSelection(value)" value="{{value.name}}"  (change)="changeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Soup</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of soupsList" class="list-group-item">
                        <input type="checkbox" [checked]="getSelection(value)" value="{{value.name}}"  (change)="changeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
             </div>
          </div>
          <div id="mobile2" class="container tab-pane fade"><br>
            <div class="container-fluid" style="display: flex;flex-wrap: wrap;">
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Mains curries</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of main_curries" class="list-group-item">
                        <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Regional Non Veg</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of regional_non_veg" class="list-group-item">
                        <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Mains Rice</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of mains_rice" class="list-group-item">
                        <input type="checkbox" [checked]="getSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Biriyani Dishes</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of biriyani_dishes" class="list-group-item">
                        <input type="checkbox" [checked]="getSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Family Pack Biryani</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of family_pack" class="list-group-item">
                        <input type="checkbox" [checked]="mainsGetSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Bread Basket</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of bread_basket" class="list-group-item">
                        <input type="checkbox" [checked]="getSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Regional Veg</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of regional_veg" class="list-group-item">
                        <input type="checkbox" [checked]="getSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Mains Fry</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of mains_fry" class="list-group-item">
                          <input type="checkbox" [checked]="getSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Mains Noodles</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of mains_noodles" class="list-group-item">
                        <input type="checkbox" [checked]="getSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">South Indian Dishes</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of south_india_dish" class="list-group-item">
                        <input type="checkbox" [checked]="getSelection(value)" value="{{value.name}}"  (change)="mainsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
             </div>
          </div>
          <div id="mobile3" class="container tab-pane fade"><br>
            <div class="container-fluid" style="display: flex;flex-wrap: wrap;">
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Extras Kids</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of extra_kids" class="list-group-item">
                        <input type="checkbox" [checked]="extrasGetSelection(value)" value="{{value.name}}"  (change)="extrasChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Extras Pickles</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of extra_pickle" class="list-group-item">
                        <input type="checkbox" [checked]="extrasGetSelection(value)" value="{{value.name}}"  (change)="extrasChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Extras</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of extra" class="list-group-item">
                        <input type="checkbox" [checked]="extrasGetSelection(value)" value="{{value.name}}"  (change)="extrasChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
             </div>
          </div>
          <div id="mobile4" class="container tab-pane fade"><br>
            <h4 class="ml-3">Desserts</h4>
            <ul class="list-group list-group-hover list-group-striped">
               <li *ngFor="let value of desserts" class="list-group-item">
                <input type="checkbox" [checked]="dessertsGetSelection(value)" value="{{value.name}}"  (change)="dessertsChangeHandler(value, $event)"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                  <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                     tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                     placement="right">
                  <i class="fa fa-info-circle" aria-hidden="true"></i>
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                  {{value.allergens}}
                  </span>
                  </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
               </li>
            </ul>
          </div>
          <div id="mobile5" class="container tab-pane fade"><br>
            <h4 class="ml-3">Kids Meal</h4>
            <ul class="list-group list-group-hover list-group-striped">
               <li *ngFor="let value of kids_meal" class="list-group-item">
                <input type="checkbox" [checked]="kidsmealGetSelection(value)" value="{{value.name}}"  (change)="kidsmealChangeHandler(value, $event)"> <img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                  <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                     tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                     placement="right">
                  <i class="fa fa-info-circle" aria-hidden="true"></i>
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                  {{value.allergens}}
                  </span>
                  </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
               </li>
            </ul>
          </div>
        </div>

        <!--Customize Menu form For Mobile-->
        <div class="col-lg-6 col-md-12" id="getquoate">
            <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                <h2>Get a Quote
                </h2>
                <p>Please fill out the following online form and a member of our team will call you as soon as possible to discuss the Food Menu and any other requirements.</p>
                <form [formGroup]="reactiveForm">
                    <div class="row">
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <select formControlName="title" [(ngModel)]="title" (click)="titleinvalid()" class="form-select" id="title">
                                    <option [ngValue]="null" disabled selected>Select Title*</option>
                                    <option>Mr</option>
                                    <option>Mrs</option>
                                    <option>Miss</option>
                                    <option>Ms</option>
                                    <option>Dr</option>
                                </select>
                                <div class="alert alert-danger" *ngIf="titleinvalidError">Title is required.</div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input formControlName="name" class="form-control" (keyup)="nameinvalid()" placeholder="Your Full Name*">
                                <div class="alert alert-danger" *ngIf="nameinvalidError">
                                    <div>Full Name is required.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                             <input name="email" type="email" formControlName="email" (keyup)="emailinvalidError()" class="form-control" placeholder="Email*"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                             <div class="alert alert-danger" *ngIf="submitted && f.email.errors ">
                                 <div *ngIf="f.email.errors.required">Email is required</div>
                                 <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                             </div>
                         </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                         <div class="form-group">

                             <ngx-intl-tel-input
                             [cssClass]="'custom'"
                             [preferredCountries]="preferredCountries"
                             [customPlaceholder]="'phone*'"
                             [enableAutoCountrySelect]="true"
                             [enablePlaceholder]="true"
                             [searchCountryFlag]="true"
                             [searchCountryField]="[
                               SearchCountryField.Iso2,
                               SearchCountryField.Name
                             ]"
                             [selectFirstCountry]="false"
                             [selectedCountryISO]="CountryISO.UnitedKingdom"
                             [maxLength]="15"
                             [phoneValidation]="true"
                             [separateDialCode]="separateDialCode"
                             [numberFormat]="PhoneNumberFormat.National"
                             name="phone"
                             (keyup)="phoneinvalidError()"
                             formControlName="phone"
                           >
                           </ngx-intl-tel-input>
                           <div class="alert alert-danger" *ngIf=" phn && f.phone.invalid "  >
                             <div *ngIf="f.phone.errors.required">Phone is required</div>
                             <div *ngIf="f.phone.invalid">Enter a valid Phone Number</div>
                             </div>
                         </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <select name="servicetype" [(ngModel)]="servicetype" (click)="serviceTypeinvalid()" formControlName="servicetype" class="form-select" (change)="onChange($event.target.value)">
                                    <option  [ngValue]="null"  disabled selected>Choose Catering Occasion*</option>
                                    <!-- <option>Live Dosa Station</option> -->
                                    <option>Family Gathering</option>
                                    <option>Festival & Social Events</option>
                                    <option>Weddings</option>
                                    <option>Film Crew Catering</option>
                                    <option>Corporate Events</option>
                                    <option>Sports Events</option>
                                    <option>Charity Fundraising Events</option>
                                     <option>Other</option></select>
                                <div class="alert alert-danger" *ngIf="serviceTypeError">Catering Occasion is required.</div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12" *ngIf="otherData">
                            <div class="form-group">
                                <input name="occasiondescription" type="text" formControlName="occasiondescription" class="form-control" placeholder="Enter Occasion Description">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input name="eventdate" type="date" formControlName="eventdate" [min]="now" [max]="maxBirthDate" class="form-control"  placeholder="Occasion Date  (yyyy-MM-dd) - Select Date" >
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <input name="eventstarttime" type="time" formControlName="eventstarttime"  class="form-control" placeholder="Occasion Start Time">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mb-3">
                            <select formControlName="eventduration" [(ngModel)]="eventduration"  class="form-select" id="eventduration">
                             <option [ngValue]="null"  disabled selected>Choose AM/ PM</option>
                             <option>PM</option>
                             <option>AM</option>
                            </select>
                            <!-- <div class="alert alert-danger" *ngIf="eventdurationinvalidError">Duration is required.</div> -->
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input name="numberofguests" type="text" formControlName="numberofguests"  class="form-control" placeholder="Guest Number (Estimate)">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input name="venueinmind" type="text" class="form-control" formControlName="venueinmind" placeholder="Venue Name/Address ">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea formControlName="message" (keyup)="messageinvalidError()" name="message" cols="30" rows="5" class="form-control" placeholder="Tell us about your catering requirements Or any food allergies...*"></textarea>
                                <div class="alert alert-danger" *ngIf="messageinvalid">Message is required.</div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label class="form-check-label text-white" for="flexCheckDefault" >
                                    By sending this form, I confirm that I have read and accept the
                                    <a routerLink="/terms-of-service">Terms</a> and <a routerLink="/privacy-policy">Privacy Policy.</a>
                                </label>
                              </div>

                        </div>
                        <div class="col-lg-12 col-md-12 d-flex">
                            <button type="submit" class="default-btn" (click)="submitData()">Send Message<span></span></button>
                            <!-- <button class="default-btn" (click)="dataReset()">Reset<span></span></button> -->
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!--Customize Menu form for mobile-->
      </div>
 </div>
