<div class="feedback-area bg-fffaf3 ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Reviews</span>
            <h2>What our customers say </h2>
            <p>
                We take our job and standards of services extremely seriously and absolutely care about your satisfaction. You will know the quality of service the moment you contact us for your catering requirement. We are very happy and honoured to have received such good feedback!

            </p>
            <!-- <div class="odemy-grid-sorting row align-items-center">
                <div class="col-lg-6 col-md-6 result-count">
                    <p>We found <span class="count">9</span> products available for you</p>
                </div>
                <div class="col-lg-6 col-md-6 ordering">
                    <div class="select-box">
                        <label>Sort By:</label>
                        <select  [formControl]="selectListValue">
                            <option *ngFor="let category of selectitem" [value]="category.link" >{{category.name}}</option>

                        </select>


                    </div>
                </div>
            </div> -->
        </div>
        <div class="feedback-slides-two">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide *ngFor="let item of feedBackData">
                    <div class="single-feedback-box">
                        <p>{{item.fields.comments}}</p>
                        <div class="client-info d-flex align-items-center">
                            <img *ngIf="item.fields.review_source === 'GBR' " src="assets/img/user1.png" class="rounded-circle" alt="image">
                            <img *ngIf="item.fields.review_source === 'IAC' " src="assets/img/iac.png" class="rounded-circle" alt="image">
                            <div class="title">
                                <h3>{{item.fields.name}},{{item.fields.role}},{{item.fields.company}}</h3>
                                <span>{{item.fields.rating}} Reviews</span>
                                <!-- <ngb-rating class="rating" [max]="5" [(rate)]="item.fields.rating" [readonly]="true"></ngb-rating> -->
                                <ngb-rating [rate]="item.fields.rating" [max]="5" [readonly]=true>
                                    <ng-template let-fill="fill">
                                      <span class="star" [ngClass]="'color'+item.fields.rating"[class.filled]="fill === 100">&#9733;</span>
                                    </ng-template>
                                </ngb-rating>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="divider2"></div>
    <div class="divider3"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>

</div>
