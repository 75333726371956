import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventEnquiryService {

  constructor(private http: HttpClient) { }

  getEventData(x: any) {
    const event_enquiry_data = this.http.post('https://vt8cp662fg.execute-api.eu-west-2.amazonaws.com/test/eventinquiry/dynamodb',x)
    const emailVerification = this.http.post('https://vt8cp662fg.execute-api.eu-west-2.amazonaws.com/test/eventinquiry/thankyouemail',x)

    forkJoin([event_enquiry_data,emailVerification]).subscribe(res =>{
        return res;
    })
  }

  cusomiseMenuData(x:any) {
    const event_enquiry_data = this.http.post('https://vt8cp662fg.execute-api.eu-west-2.amazonaws.com/test/customizemenu/dynamodb',x)
    const emailVerification = this.http.post('https://vt8cp662fg.execute-api.eu-west-2.amazonaws.com/test/customizemenu/thankyouemail',x)

    forkJoin([event_enquiry_data,emailVerification]).subscribe(res =>{
        return res;
    })
  }
}
