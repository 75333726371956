import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-buttons-block',
  templateUrl: './services-buttons-block.component.html',
  styleUrls: ['./services-buttons-block.component.scss']
})
export class ServicesButtonsBlockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
