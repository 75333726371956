import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-veg-or-non-veg-combos',
  templateUrl: './veg-or-non-veg-combos.component.html',
  styleUrls: ['./veg-or-non-veg-combos.component.scss']
})
export class VegOrNonVegCombosComponent implements OnInit {
    @HostListener('window:scroll', ['$event'])

    checkScroll() {
      this.isSticky = window.pageYOffset >= 250;
    }
    isSticky: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

}
