<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Reviews</li>
            </ul>
            <h2>Reviews</h2>
        </div>
    </div>
    <
</div>

<div class="profile-area pt-5 pb-70">
    <div class="container">
        <app-feedback></app-feedback>
    </div>
</div>

