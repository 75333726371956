<div class="error-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <h3>Thank you for subscribing to our newsletter!</h3>
                    <h6>{{data1}}</h6>
                    <p>We'll be in touch very soon.</p>
                    <a routerLink="/" class="btn btn-primary">Go to Homepage</a>
                </div>
            </div>
        </div>
    </div>
</div>
