import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DistanceLearningComponent } from './components/pages/distance-learning/distance-learning.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { SuccessStoryComponent } from './components/pages/success-story/success-story.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/pages/terms-of-service/terms-of-service.component';
import { CateringServicesComponent } from './components/pages/catering-services/catering-services.component';
import { MenusComponent } from './components/pages/menus/menus.component';
import { AllergyAdviceComponent } from './components/pages/allergy-advice/allergy-advice.component';
import { CookiePolicyComponent } from './components/pages/cookie-policy/cookie-policy.component';
import { SiteMapComponent } from './components/pages/site-map/site-map.component';
import { EventEnquiryComponent } from './components/pages/event-enquiry/event-enquiry.component';
import { FilmCrewCateringComponent } from './components/pages/film-crew-catering/film-crew-catering.component';
import { EventComponent } from './components/common/event/event.component';
import { FamilyGatheringsComponent } from './components/pages/family-gatherings/family-gatherings.component';
import { FullMenuComponent } from './components/pages/full-menu/full-menu.component';
import { PopularCateringCombosComponent } from './components/pages/popular-catering-combos/popular-catering-combos.component';
import { LiveDosaStationComponent } from './components/pages/live-dosa-station/live-dosa-station.component';
import { CustomerLocationsComponent } from './components/pages/customer-locations/customer-locations.component';
import { ScpComponent } from './components/pages/scp/scp.component';
import { FestivalAndSocialEventsComponent } from './components/pages/festival-and-social-events/festival-and-social-events.component';
import { WeddingsComponent } from './components/pages/weddings/weddings.component';
import { CorporateEventsComponent } from './components/pages/corporate-events/corporate-events.component';
import { SportsEventsComponent } from './components/pages/sports-events/sports-events.component';
import { CharitFundraisingEventsComponent } from './components/pages/charit-fundraising-events/charit-fundraising-events.component';
import { ReviewsComponent } from './components/pages/reviews/reviews.component';
import { SitemapComponent } from './components/pages/sitemap/sitemap.component';
import { VegMenuComponent } from './components/pages/veg-menu/veg-menu.component';
import { BestSellingCombosComponent } from './components/pages/best-selling-combos/best-selling-combos.component';
import { LunchOrDinnerCombosComponent } from './components/pages/lunch-or-dinner-combos/lunch-or-dinner-combos.component';
import { BreakfastComboComponent } from './components/pages/breakfast-combo/breakfast-combo.component';
import { ThankYouComponent } from './components/pages/thank-you/thank-you.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { VegMenuListComponent } from './components/pages/veg-menu-list/veg-menu-list.component';
import { LiveStationMenuListComponent } from './components/pages/live-station-menu-list/live-station-menu-list.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { WeddingCombosComponent } from './components/pages/wedding-combos/wedding-combos.component';
import { VegOrNonVegCombosComponent } from './components/pages/veg-or-non-veg-combos/veg-or-non-veg-combos.component';
import { ThankyouNewsLetterComponent } from './components/common/thankyou-news-letter/thankyou-news-letter.component';
import { CustomizeMenuComponent } from './customize-menu/customize-menu.component';
import { AuditAndCertificatesComponentComponent } from './components/common/audit-and-certificates-component/audit-and-certificates-component.component';
import { LeaveYourFeedBackComponent } from './components/common/leave-your-feed-back/leave-your-feed-back.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { blogdetailsComponent } from './components/common/blog-details/blogdetails.component';
import { UnsubscribeNewsLetterComponent } from './components/common/Unsubscribe-newsletter/Unsubscribe-Newsletter.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { FestivelComboComponent } from './components/pages/festivelCombo/festivelCombo.component';
import { CanapesMenuComponent } from './components/pages/canapes-menu/canapes-menu.component';
import { TeacherComponent } from './components/pages/teacher/teacher.component';


const routes: Routes = [
    {path: '', component: DistanceLearningComponent},
    {path: 'catering-services', component: CateringServicesComponent},
    {path: 'menus', component: MenusComponent},
    // {path: 'our-gallery', component: OurGalleryComponent},
    {path: 'our-gallery', component: GalleryComponent},

    {path: 'allergy-advice', component: AllergyAdviceComponent},
    {path: 'cookie-policy', component: CookiePolicyComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},

    {path:'blog', component:BlogComponent},
    {path:'blogdetails', component:blogdetailsComponent},
    {path:'venues', component:TeacherComponent},

    {path: 'terms-of-service', component: TermsOfServiceComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'contact', component: ContactUsComponent},
    {path: 'site-map', component: SiteMapComponent},
    {path: 'event-enquiry', component: EventEnquiryComponent},
    {path: 'film-crew-catering', component: FilmCrewCateringComponent},
    {path: 'family-gatherings', component: FamilyGatheringsComponent},
    {path: 'event', component: EventComponent},
    {path: 'our-full-menu', component: FullMenuComponent},
    {path: 'cutomize-menu', component: CustomizeMenuComponent},
    {path: 'popular-catering-combos', component: PopularCateringCombosComponent},
    {path: 'canapes-menu', component: CanapesMenuComponent},
    {path: 'live-dosa-station', component: LiveDosaStationComponent},
    {path: 'customer-locations', component: CustomerLocationsComponent},
    {path: 'about-us', component: SuccessStoryComponent},
    {path: 'scp', component: ScpComponent},
    {path:'festival-and-events',component: FestivalAndSocialEventsComponent},
    {path:'weddings',component: WeddingsComponent},
    {path:'corporate_events',component: CorporateEventsComponent},
    {path:'sports_events',component: SportsEventsComponent},
    {path:'charity_fundraising_events',component: CharitFundraisingEventsComponent},
    {path:'reviews',component: ReviewsComponent},
    {path:'sitemap',component: SitemapComponent},
    {path:'veg-combos',component: VegMenuComponent},
    {path:'veg-and-nonveg-combos',component: VegOrNonVegCombosComponent},
    {path:'festivel-combo',component: FestivelComboComponent},
    {path: 'pure-vegmenu', component: VegMenuListComponent},
    {path:'best-selling-combos',component: BestSellingCombosComponent},
    {path:'lunch-or-dinner-combos',component: LunchOrDinnerCombosComponent},
    {path:'breakfast-combos',component: BreakfastComboComponent},
    {path:'thank_you',component: ThankYouComponent},
    {path:'live-stations',component: LiveStationMenuListComponent},
    {path:'wedding-combos',component: WeddingCombosComponent},
    {path:'subscribeNewsletter' ,component:SubscribeComponent},
    {path:'thank_you_newsletter',component:ThankyouNewsLetterComponent},
    {path:'unsubscribeNewsletter' ,component:UnsubscribeNewsLetterComponent},
    {path:'audit-and-certificates',component:AuditAndCertificatesComponentComponent},
    {path:'error',component: ErrorPageComponent},
    {path:'leave_your_feedback',component:LeaveYourFeedBackComponent},
    {path:'error',component: ErrorPageComponent},
    {path: '**', component: ErrorComponent}
   /*
    // Here add new pages component
    */
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
