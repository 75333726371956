<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Breakfast Combos</li>
            </ul>
            <h2>Breakfast Combos</h2>
            <br/>
            <p><i class='bx bx-food-menu'></i>  &nbsp;We have a rich selection of <a routerLink="/pure-vegmenu">vegetarian menus</a>. We can also accommdate your <a routerLink="/allergy-advice" tooltip="Spice: Low | medium | High;
                V: Suitable for Vegeterians;
                Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                                    placement="right">unique dietary needs and spice preferences</a>, if you could let us know in advance.</p>.


            <p> <i class='bx bx-envelope'></i> &nbsp;Please
                <a routerLink="/event-enquiry">contact us</a>
                 to discuss your event so we can provide an appropriate price for you.</p>

        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="privacy-policy-area ptb-100" id="desktop">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_insight">
                        <ul>
                            <li>
                                <a routerLink="/best-selling-combos"
                                    >Best Selling Combos</a
                                >
                            </li>
                            <li>
                                <a routerLink="/veg-combos">Pure Veg Combos</a>
                            </li>
                            <li><a routerLink="/veg-and-nonveg-combos">Veg / Non Veg Combos</a></li>
                            <li>
                                <a routerLink="/wedding-combos"
                                    >Wedding Combos</a
                                >
                            </li>
                            <li class="active">
                                <a routerLink="/breakfast-combos"
                                    >Breakfast Combos</a
                                >
                            </li>
                            <li>
                                <a routerLink="/lunch-or-dinner-combos"
                                    >Lunch/Dinner Combos</a
                                >
                            </li>
                        </ul>
                    </section>
                </aside>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <aside class="widget-area">
                                <img
                                    src="assets/img/catering-services/breakfast c1.png"
                                    class="main-image"
                                    alt="image"
                                />
                            </aside>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <aside class="widget-area">
                                <img
                                    src="assets/img/catering-services/_breakfast c2.png"
                                    class="main-image"
                                    alt="image"
                                />
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid p-0">
    <div class="mt-3 mobile-mode">
        <ul class="nav nav-tabs fixed-top" role="tablist" [ngClass]="{'mat-elevation-z5' : true, 'sticky' : isSticky}">
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link" id="v-pills-home-tab" data-toggle="pill" href="#mobile2" role="tab" aria-controls="v-pills-home" aria-selected="true">Best Selling</a>
            </li>
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#mobile1" role="tab" aria-controls="v-pills-profile" aria-selected="false">Pure Veg</a>
            </li>
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#mobile6" role="tab" aria-controls="v-pills-settings" aria-selected="false">Veg / Non Veg</a>
             </li>
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#mobile3" role="tab" aria-controls="v-pills-settings" aria-selected="false">Wedding</a>
            </li>
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link active" id="v-pills-settings-tab" data-toggle="pill" href="#mobile4" role="tab" aria-controls="v-pills-settings" aria-selected="false">Breakfast</a>
            </li>
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#mobile5" role="tab" aria-controls="v-pills-settings" aria-selected="false">Lunch/Dinner</a>
            </li>
          </ul>
        <!-- Tab panes -->
        <div class="tab-content">
            <div id="mobile2" class="container tab-pane active">
                <br>
                <div class="row">
                   <div class="col-lg-4 col-md-12">
                      <aside class="widget-area">
                         <img src="assets/img/mainmenu/c1.png" class="main-image" alt="image">
                      </aside>
                   </div>
                   <div class="col-lg-4 col-md-12">
                      <aside class="widget-area">
                         <img src="assets/img/mainmenu/c2.png" class="main-image" alt="image">
                      </aside>
                   </div>
                   <div class="col-lg-4 col-md-12">
                      <aside class="widget-area">
                         <img src="assets/img/mainmenu/c3.png" class="main-image" alt="image">
                      </aside>
                   </div>
                </div>
                <div class="row">
                   <div class="col-lg-4 col-md-12">
                      <aside class="widget-area">
                         <br/>
                         <img src="assets/img/mainmenu/c4.png" class="main-image" alt="image">
                      </aside>
                   </div>
                   <div class="col-lg-4 col-md-12">
                      <aside class="widget-area">
                         <br/>
                         <img src="assets/img/mainmenu/c5.png" class="main-image" alt="image">
                      </aside>
                   </div>
                   <div class="col-lg-4 col-md-12">
                      <aside class="widget-area">
                         <br/>
                         <img src="assets/img/mainmenu/c6.png" class="main-image" alt="image">
                      </aside>
                   </div>
                </div>
                <div class="row">
                   <div class="col-lg-4 col-md-12">
                      <aside class="widget-area">
                         <br/>
                         <img src="assets/img/mainmenu/c7.png" class="main-image" alt="image">
                      </aside>
                   </div>
                   <div class="col-lg-4 col-md-12">
                      <aside class="widget-area">
                         <br/>
                         <img src="assets/img/mainmenu/c8.png" class="main-image" alt="image">
                      </aside>
                   </div>
                   <div class="col-lg-4 col-md-12">
                      <aside class="widget-area">
                         <br/>
                         <img src="assets/img/mainmenu/c9.png" class="main-image" alt="image">
                      </aside>
                   </div>
                </div>
                <div class="row">
                   <div class="col-lg-4 col-md-12">
                      <aside class="widget-area">
                         <br/>
                         <img src="assets/img/mainmenu/c10.png" class="main-image" alt="image">
                      </aside>
                   </div>
                   <div class="col-lg-4 col-md-12">
                      <aside class="widget-area">
                         <br/>
                         <img src="assets/img/mainmenu/c11.png" class="main-image" alt="image">
                      </aside>
                   </div>
                   <div class="col-lg-4 col-md-12">
                      <aside class="widget-area">
                         <br/>
                         <img src="assets/img/mainmenu/c12.png" class="main-image" alt="image">
                      </aside>
                   </div>
                </div>
             </div>
          <div id="mobile1" class="container tab-pane fade"><br>
            <div class="row">
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <img src="assets/img/catering-services/pure-veg c1.png" class="main-image" alt="image">
                   </aside>
                </div>
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <img src="assets/img/catering-services/pure-veg c2.png" class="main-image" alt="image">
                   </aside>
                </div>
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <img src="assets/img/catering-services/pure-veg c3.png" class="main-image" alt="image">
                   </aside>
                </div>
             </div>
          </div>
          <div id="mobile3" class="container tab-pane fade"><br>
            <div class="row">
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <img src="assets/img/catering-services/breakfast c1.png" class="main-image" alt="image">
                   </aside>
                </div>
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <img src="assets/img/catering-services/south indian lunch.png" class="main-image" alt="image">
                   </aside>
                </div>
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <img src="assets/img/catering-services/pure-veg c2.png" class="main-image" alt="image">
                   </aside>
                </div>
             </div>
             <div class="row">
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <br/>
                      <img src="assets/img/catering-services/_breakfast c2.png" class="main-image" alt="image">
                   </aside>
                </div>
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <br/>
                      <img src="assets/img/catering-services/pure-veg c3.png" class="main-image" alt="image">
                   </aside>
                </div>
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <br/>
                      <img src="assets/img/catering-services/pure non-veg.png" class="main-image" alt="image">
                   </aside>
                </div>
             </div>
          </div>
          <div id="mobile4" class="container tab-pane fade"><br>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                   <aside class="widget-area">
                      <img
                         src="assets/img/catering-services/breakfast c1.png"
                         class="main-image"
                         alt="image"
                         />
                   </aside>
                </div>
                <div class="col-lg-6 col-md-6">
                   <aside class="widget-area">
                      <img
                         src="assets/img/catering-services/_breakfast c2.png"
                         class="main-image"
                         alt="image"
                         />
                   </aside>
                </div>
             </div>
          </div>
          <div id="mobile5" class="container tab-pane fade"><br>
            <div class="row">
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <img src="assets/img/catering-services/pure-veg c1.png" class="main-image" alt="image">
                   </aside>
                </div>
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <img src="assets/img/catering-services/pure-veg c2.png" class="main-image" alt="image">
                   </aside>
                </div>
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <img src="assets/img/catering-services/pure-veg c3.png" class="main-image" alt="image">
                   </aside>
                </div>
             </div>
             <div class="row">
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <br/>
                      <img src="assets/img/catering-services/non-veg c1.png" class="main-image" alt="image">
                   </aside>
                </div>
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <br/>
                      <img src="assets/img/mainmenu/c4.png" class="main-image" alt="image">
                   </aside>
                </div>
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <br/>
                      <img src="assets/img/catering-services/non-veg c3.png" class="main-image" alt="image">
                   </aside>
                </div>
             </div>
          </div>
          <div id="mobile6" class="container tab-pane fade">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <img src="assets/img/nonveg/1.png" class="main-image" alt="image">
                   </aside>
                </div>
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <img src="assets/img/nonveg/non-veg1.png" class="main-image" alt="image">
                   </aside>
                </div>
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <img src="assets/img/nonveg/non-veg3.png" class="main-image" alt="image">
                   </aside>
                </div>
             </div>
             <div class="row">
                <div class="col-lg-4 col-md-4">
                   <aside class="widget-area">
                      <br/>
                      <img src="assets/img/nonveg/non-veg2.png" class="main-image" alt="image">
                   </aside>
                </div>
             </div>
          </div>
        </div>
      </div>
 </div>
