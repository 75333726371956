import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomerLocationsService {

  constructor(private http: HttpClient) { }

  getLocation() {
   return this.http.get('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/metadata/getall')
  }
}
