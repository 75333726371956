<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Catering Services</li>
            </ul>
            <h2>Catering Services</h2>
        </div>
    </div>
    <
</div>

<div class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="odemy-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>We offer <span class="count">Catering Services</span> for a variety of occasions.</p>
            </div>
            <!-- <div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Sort By:</label>
                    <select>
                        <option>Default</option>
                        <option>Popularity</option>
                        <option>Latest</option>
                        <option>Price: low to high</option>
                        <option>Price: high to low</option>
                    </select>
                </div>
            </div> -->
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image" routerLink="/live-dosa-station">
                        <a routerLink="/live-dosa-station" class="d-block image">
                            <img src="assets/img/catering-services/iac-1.png" alt="image">
                        </a>
                        <a routerLink="/live-stations" class="fav"><i class="flaticon-people"></i></a>
                        <div class="price shadow"><i class="flaticon-heart"></i></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/live-dosa-station">Live Dosa Station</a></h3>
                        <div class="rating">
                            <i class='bx bxs-carousel'></i>
                            <div class="rating-total">
                                <a routerLink="/our-gallery">Gallery&nbsp;&nbsp; </a>
                            </div>
                            <i class='bx bxs-food-menu'></i>
                            <div class="rating-total">
                                <a routerLink="/menus">Menus&nbsp;&nbsp; </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image" routerLink="/family-gatherings">
                        <a routerLink="/family-gatherings" class="d-block image">
                            <img src="assets/img/catering-services/family-gatherings.png" alt="image">
                        </a>
                        <a routerLink="/family-gatherings" class="fav"><i class="flaticon-people"></i></a>
                        <div class="price shadow"><i class="flaticon-heart"></i></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/family-gatherings">Family Gatherings</a></h3>
                        <div class="rating">
                            <i class='bx bxs-carousel'></i>
                            <div class="rating-total">
                                <a routerLink="/our-gallery">Gallery&nbsp;&nbsp; </a>
                            </div>
                            <i class='bx bxs-food-menu'></i>
                            <div class="rating-total">
                                <a routerLink="/menus">Menus&nbsp;&nbsp; </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image" routerLink="/festival-and-events">
                        <a routerLink="/festival-and-events" class="d-block image">
                            <img src="assets/img/catering-services/festivals.png" alt="image">
                        </a>
                        <a routerLink="/festival-and-events" class="fav"><i class="flaticon-people"></i></a>
                        <div class="price shadow"><i class="flaticon-heart"></i></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/festival-and-events">Festival & Social Events</a></h3>
                        <div class="rating">
                            <i class='bx bxs-carousel'></i>
                            <div class="rating-total">
                                <a routerLink="/our-gallery">Gallery&nbsp;&nbsp; </a>
                            </div>
                            <i class='bx bxs-food-menu'></i>
                            <div class="rating-total">
                                <a routerLink="/menus">Menus&nbsp;&nbsp; </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image" routerLink="/weddings">
                        <a routerLink="/weddings" class="d-block image">
                            <img src="assets/img/catering-services/wedding.png" alt="image">
                        </a>
                        <a routerLink="/weddings" class="fav"><i class="flaticon-people"></i></a>
                        <div class="price shadow"><i class="flaticon-heart"></i></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/weddings">Weddings</a></h3>
                        <div class="rating">
                            <i class='bx bxs-carousel'></i>
                            <div class="rating-total">
                                <a routerLink="/our-gallery">Gallery&nbsp;&nbsp; </a>
                            </div>
                            <i class='bx bxs-food-menu'></i>
                            <div class="rating-total">
                                <a routerLink="/menus">Menus&nbsp;&nbsp; </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image" routerLink="/film-crew-catering">
                        <a routerLink="/film-crew-catering" class="d-block image">
                            <img src="assets/img/catering-services/film-catering.png" alt="image">
                        </a>
                        <a routerLink="/film-crew-catering" class="fav"><i class="flaticon-people"></i></a>
                        <div class="price shadow"><i class="flaticon-heart"></i></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/film-crew-catering">Film Crew Catering</a></h3>
                        <div class="rating">
                            <i class='bx bxs-carousel'></i>
                            <div class="rating-total">
                                <a routerLink="/our-gallery">Gallery&nbsp;&nbsp; </a>
                            </div>
                            <i class='bx bxs-food-menu'></i>
                            <div class="rating-total">
                                <a routerLink="/menus">Menus&nbsp;&nbsp; </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image" routerLink="/sports_events">
                        <a routerLink="/sports_events" class="d-block image">
                            <img src="assets/img/catering-services/sports.png" alt="image">
                        </a>
                        <a routerLink="/sports_events" class="fav"><i class="flaticon-people"></i></a>
                        <div class="price shadow"><i class="flaticon-heart"></i></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/sports_events">Sports Events</a></h3>
                        <div class="rating">
                            <i class='bx bxs-carousel'></i>
                            <div class="rating-total">
                                <a routerLink="/our-gallery">Gallery&nbsp;&nbsp; </a>
                            </div>
                            <i class='bx bxs-food-menu'></i>
                            <div class="rating-total">
                                <a routerLink="/menus">Menus&nbsp;&nbsp; </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image" routerLink="/charity_fundraising_events">
                        <a routerLink="/charity_fundraising_events" class="d-block image">
                            <img src="assets/img/catering-services/charity.png" alt="image">
                        </a>
                        <a routerLink="/charity_fundraising_events" class="fav"><i class="flaticon-people"></i></a>
                        <div class="price shadow"><i class="flaticon-heart"></i></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/charity_fundraising_events">Charity Fundraising Events</a></h3>
                        <div class="rating">
                            <i class='bx bxs-carousel'></i>
                            <div class="rating-total">
                                <a routerLink="/our-gallery">Gallery&nbsp;&nbsp; </a>
                            </div>
                            <i class='bx bxs-food-menu'></i>
                            <div class="rating-total">
                                <a routerLink="/menus">Menus&nbsp;&nbsp; </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-item-box">
                    <div class="courses-image" routerLink="/corporate_events">
                        <a routerLink="/corporate_events" class="d-block image">
                            <img src="assets/img/catering-services/corporate.png" alt="image">
                        </a>
                        <a routerLink="/corporate_events" class="fav"><i class="flaticon-people"></i></a>
                        <div class="price shadow"><i class="flaticon-heart"></i></div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/corporate_events">Corporate Events</a></h3>
                        <div class="rating">
                            <i class='bx bxs-carousel'></i>
                            <div class="rating-total">
                                <a routerLink="/our-gallery">Gallery&nbsp;&nbsp; </a>
                            </div>
                            <i class='bx bxs-food-menu'></i>
                            <div class="rating-total">
                                <a routerLink="/menus">Menus&nbsp;&nbsp; </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
