import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    data: any = [];




    public _album  = [];
    public _album_videos  = [];


    constructor(public _lightbox: Lightbox, private router: Router,private http: HttpClient) {
        for (let i = 1; i <= 65; i++) {
            const src = 'assets/img/gallery/iac-' + i + '.png';
            const caption = 'Image ' + i;
            const thumb = 'assets/img/gallery/iac-' + i + '.png';
            const album = {
                src: src,
                caption: caption,
                thumb: thumb
            };
            this._album.push(album);
        }

        for (let i = 1; i <= 5; i++) {
            const src = 'https://master.d3rohc12j4uf84.amplifyapp.com/assets/img/gallery/videos/iac-' + i + '.mp4';
            const caption = 'Image ' + i;
            const thumb = 'https://master.d3rohc12j4uf84.amplifyapp.com/assets/img/gallery/videos/iac-' + i + '.mp4';
            const album_video = {
                src: src,
                caption: caption,
                thumb: thumb
            };
            this._album_videos.push(album_video);
        }

    }

    ngOnInit(): void {
        this.getGalleries()
    }

     // Tabs
     currentTab = 'tab1';
     switchTab(event: MouseEvent, tab: string) {
         event.preventDefault();
         this.currentTab = tab;
         if(tab == 'tab1' && this.data.length == 0){
            this.getGalleries();
         }
     }

    open(index: number): void {
        // open lightbox
        this._lightbox.open(this._album, index);
    }

    close(): void {
        // close lightbox programmatically
        this._lightbox.close();
    }


    getGalleries() {
        this.http.get(`https://vt8cp662fg.execute-api.eu-west-2.amazonaws.com/test/frontend_assets/get?q=gallery`).subscribe((res:any)=>{
            this.data =res;
        });
    }

}

