<div class="page-title-area">
    <div class="container">
       <div class="page-title-content">
          <ul>
             <li><a routerLink="/">Home</a></li>
             <li>About Us</li>
          </ul>
          <h2>About Us - Our Story</h2>
       </div>
    </div>
    <
 </div>
 <div class="about-area-three ptb-100">
    <div class="container">
       <div class="row align-items-center">
          <div class="col-lg-5 col-md-12">
             <div class="about-content-box">
                <span class="sub-title">INDIAN AROMA CATERERS</span>
                <h2>Catering with passion</h2>
                <p>The Indian Aroma brand is known for the real enthusiasm with which its team prepares and serves each meal. Among our clientele are movie moguls, film and television crews, religious and cultural events, charity and business events, and religious and cultural events. The restaurant is known not just for its traditional recipes, but also for the high quality of its food and exceptional service.</p>
                <p>It all began with the Indian Aroma Restaurant, which specialises in bringing exquisite Indian cuisine to the Western world. We want to give you the option of trying the greatest vegetarian and non-vegetarian foods, increasing the flavours and textures with our traditional Indian recipes, such as Indian street food. We want to leave a legacy with our team and continue to use fresh, long-lasting ingredients. We strive to make all of our clients pleased at all times!
                </p>
             </div>
          </div>
          <div class="col-lg-7 col-md-12">
             <div class="about-img">
                <div class="image">
                   <img src="assets/img/about/about1.png" class="aroma" alt="image">
                   <img src="assets/img/about/gulabjamun.png" class="gulab" alt="image" style="left:20px;width:40%">
                </div>
                <div class="shape17"><img src="assets/img/shape16.png" alt="image"></div>
                <div class="shape18"><img src="assets/img/shape17.png" alt="image"></div>
                <div class="shape19"><img src="assets/img/shape18.png" alt="image"></div>
                <div class="shape20"><img src="assets/img/shape19.png" alt="image"></div>
                <div class="shape21"><img src="assets/img/shape20.png" alt="image"></div>
                <!-- <div class="shape22"><img src="assets/img/shape21.png" alt="image"></div> -->
                <div class="shape23"><img src="assets/img/shape22.png" alt="image"></div>
             </div>
          </div>
       </div>
       <div class="row align-items-center">
          <div class="col-lg-12 col-md-12">
             <div class="about-content-box">
                <p>We've made a commitment to you. Our top objective is to pay attention to you, establish a positive relationship, and exceed your expectations. We take pride in building long-term relationships with our clients based on trust. Our clients hold us to high expectations, and we strive to exceed those expectations. We recognise that our clients demand nothing less than perfection from us.</p>
                <p>When it comes to our clients, we believe that accomplishing their goals requires no compromises, and we incorporate this philosophy throughout our business and culture. We take pride in being premium properties of beautiful experiences; we are dedicated to assisting you in focusing on making your events once-in-a-lifetime memories, and we want you to rest easy knowing that making once-in-a-lifetime memories is what we do on a daily basis.
                </p>
                <p>Our services are of the highest quality, unrivalled, and most importantly, unforgettable. From a vast venue to a single grain of rice on a dish, we put our hearts and souls into all we do. We take pleasure in our work and are convinced that you will join the Indian Aroma family of satisfied customers. Clients of Indian Aroma have the assurance that our advice and recommendations are based on the expertise of a staff with many years of experience.</p>
             </div>
          </div>
       </div>
    </div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
 </div>
 <app-feedback></app-feedback>
 <div class="premium-access-area bg-f9f9f9 ptb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
 </div>
