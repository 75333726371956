<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Menus</li>
            </ul>
            <h2>Menus</h2>
        </div>
    </div>
    <div ></div>
</div>

<div class="categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/about/abount3.png" alt="image">
                    <div class="content">
                        <h3>Festive Combos</h3>
                        <span>Catering Combos</span>
                    </div>
                    <a routerLink="/festivel-combo" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/about/abount3.png" alt="image">
                    <div class="content">
                        <h3>Best Selling Combos</h3>
                        <span>Catering Combos</span>
                    </div>
                    <a routerLink="/best-selling-combos" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/about/abount3.png" alt="image">
                    <div class="content">
                        <h3>Pure Veg Combos</h3>
                        <span>Catering Combos</span>
                    </div>
                    <a routerLink="/veg-combos" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/about/abount3.png" alt="image">
                    <div class="content">
                        <h3>Veg / Non Veg Combos</h3>
                        <span>Catering Combos</span>
                    </div>
                    <a routerLink="/veg-and-nonveg-combos" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/about/abount3.png" alt="image">
                    <div class="content">
                        <h3>Breakfast Combos</h3>
                        <span>Catering Combos</span>
                    </div>
                    <a routerLink="/breakfast-combos" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/about/abount3.png" alt="image">
                    <div class="content">
                        <h3>Lunch/Dinner Combos</h3>
                        <span>Catering Combos</span>
                    </div>
                    <a routerLink="/lunch-or-dinner-combos" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/about/abount3.png" alt="image">
                    <div class="content">
                        <h3>Wedding Combos</h3>
                        <span>Catering Combos</span>
                    </div>
                    <a routerLink="/wedding-combos" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/about/abount3.png" alt="image">
                    <div class="content">
                        <h3>Canapés Menu</h3>
                        <span>Catering Combos</span>
                    </div>
                    <a routerLink="/canapes-menu" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/about/abount3.png" alt="image">
                    <div class="content">
                        <h3>Live Stations Menu</h3>
                        <span>Menus</span>
                    </div>
                    <a routerLink="/live-stations" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/about/abount3.png" alt="image">
                    <div class="content">
                        <h3>Pure Veg Menu</h3>
                        <span>Menus</span>
                    </div>
                    <a routerLink="/pure-vegmenu" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/about/abount3.png" alt="image">
                    <div class="content">
                        <h3>Our Full Menu</h3>
                        <span>Menus</span>
                    </div>
                    <a routerLink="/our-full-menu" class="link-btn"></a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="assets/img/about/abount3.png" alt="image">
                    <div class="content">
                        <h3>Customise Menu</h3>
                        <span>Catering Combos</span>
                    </div>
                    <a routerLink="/cutomize-menu" class="link-btn"></a>
                </div>
            </div>


        </div>
    </div>
</div>

<div class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>
