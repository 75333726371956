import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-festivelCombo',
  templateUrl: './festivelCombo.component.html',
  styleUrls: ['./festivelCombo.component.css']
})
export class FestivelComboComponent implements OnInit {

    @HostListener('window:scroll', ['$event'])

    checkScroll() {
      this.isSticky = window.pageYOffset >= 250;
    }
    isSticky: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  scrollToTop(): void {
    window.scrollTo(0, 0);
  }
}
