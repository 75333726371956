<div class="page-title-area">
    <div class="container">
       <div class="page-title-content">
          <ul>
             <li><a routerLink="/">Home</a></li>
             <li>Canapés Menu</li>
          </ul>
          <h2>Canapés Menu</h2>
          <br/>
          <p><i class='bx bx-food-menu'></i>  &nbsp;We have a rich selection of <a routerLink="/pure-vegmenu">vegetarian menus</a>. We can also accommdate your <a routerLink="/allergy-advice" tooltip="Spice: Low | medium | High;
             V: Suitable for Vegeterians;
             Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
             placement="right">unique dietary needs and spice preferences</a>, if you could let us know in advance.</p>.
          <p> <i class='bx bx-envelope'></i> &nbsp;Please
             <a routerLink="/event-enquiry">contact us</a>
             to discuss your event so we can provide an appropriate price for you.
          </p>
       </div>
    </div>
    <div ></div>
 </div>

 <div class="container" id="desktop">
    <div class="row">
       <div class="col-lg-3">
          <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
             <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Vegetarian</a>
             <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Non-Vegetarian</a>
             <!-- <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">Extras</a>
             <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#data2" role="tab" aria-controls="v-pills-settings" aria-selected="false">Desserts</a>
             <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#data6" role="tab" aria-controls="v-pills-settings" aria-selected="false">Kids Meal</a> -->
          </div>
       </div>
       <div class="col-lg-9">
          <div class="tab-content" id="v-pills-tabContent">
             <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                <div class="container-fluid" style="display: flex;flex-wrap: wrap;">
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Vegetarian Canapés</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Cocktail Veg Samosa
                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Cocktail Spring Rolls
                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Achaari Paneer Tikka
                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Tandoori Paneer Tikka
                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Bhel Puri Chaat
                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Mini Masala Papad
                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Mini Aloo Tikki
                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Tandoori Haloumi
                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Paneer Tikka (Tandoori)
                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Chilli Mogo
                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Manchurian Gobi
                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Mini Chili Idly
                        </li>
                      </ul>
                   </div>
                   <!-- <div class="col-lg-4 p-3">
                      <h4 class="ml-3"></h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of classic_starters" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Street Food Starters</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of street_tandoori_starts" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Tandoori Starters</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of tandoori_starts" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                                <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                    {{value.description}}
                                </div>

                                <button type="button"  class="read-btn" (click)="showText()">
                                    {{ isReadMore ? 'Read More': 'Read Less' }}
                                  </button>
                                </div>
                        </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Soup</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of soupsList" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div> -->
                </div>
             </div>
             <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div class="container-fluid" style="display: flex;flex-wrap: wrap;">
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Non-Vegetarian Canapés</h4>
                      <ul class="list-group list-group-hover list-group-striped">

                         <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Mixed Grill


                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Cocktail Chicken Tikka

                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Mini Seekh Kebabs

                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Chicken Tikka (boneless)

                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Fried Massalla Fish (Salmon)
                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Tandoori Chicken Tikka (boneless)

                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Mini Chicken Samosa

                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Chicken Lollipops

                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Chicken Pakora

                        </li>
                        <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                            Lamb Samosas

                        </li>

                      </ul>
                   <!-- </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Regional Non Veg</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of regional_non_veg" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Mains Rice</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of mains_rice" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                         </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Biriyani Dishes</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of biriyani_dishes" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Family Pack Biryani</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of family_pack" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Bread Basket</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of bread_basket" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Regional Veg</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of regional_veg" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Mains Fry</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of mains_fry" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Mains Noodles</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of mains_noodles" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">South Indian Dishes</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of south_india_dish" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div> -->
                </div>
             </div>
             <!-- <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div class="container-fluid" style="display: flex;flex-wrap: wrap;">
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Extras Kids</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of extra_kids" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Extras Pickles</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of extra_pickle" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div>
                   <div class="col-lg-4 p-3">
                      <h4 class="ml-3">Extras</h4>
                      <ul class="list-group list-group-hover list-group-striped">
                         <li *ngFor="let value of extra" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                            <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                               tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                               placement="right">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                            {{value.allergens}}
                            </span>
                            </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                            <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                      </ul>
                   </div>
                </div>
             </div>
             <div class="tab-pane fade" id="data2" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <h4 class="ml-3">Desserts</h4>
                <ul class="list-group list-group-hover list-group-striped">
                   <li *ngFor="let value of desserts" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                      <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                         tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                         placement="right">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                      {{value.allergens}}
                      </span>
                      </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                    </li>
                </ul>
             </div>
             <div class="tab-pane fade" id="data6" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <h4 class="ml-3">Kids Meal</h4>
                <ul class="list-group list-group-hover list-group-striped">
                   <li *ngFor="let value of kids_meal" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                      <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                         tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                         placement="right">
                      <i class="fa fa-info-circle" aria-hidden="true"></i>
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                      {{value.allergens}}
                      </span>
                      </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                    </li>
                </ul>
             </div> -->
          </div>
       </div>
    </div>
    </div>
</div>

 <div class="container-fluid p-0">
    <div class="mt-3 mobile-mode">
        <ul class="nav nav-tabs fixed-top" role="tablist" [ngClass]="{'mat-elevation-z5' : true, 'sticky' : isSticky}">
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#mobile1" role="tab" aria-controls="v-pills-home" aria-selected="true">Vegetarian</a>
            </li>
            <li class="nav-item" (click)="scrollToTop()">
                <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#mobile2" role="tab" aria-controls="v-pills-profile" aria-selected="false">Non-Vegetarian</a>
            </li>

          </ul>
        <!-- Tab panes -->
        <div class="tab-content">
          <div id="mobile1" class="container tab-pane active"><br>
            <div class="container-fluid" style="display: flex;flex-wrap: wrap;">
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Vegetarian</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                        Cocktail Veg Samosa
                    </li>
                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                        Cocktail Spring Rolls
                    </li>
                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                        Achaari Paneer Tikka
                    </li>
                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                        Tandoori Paneer Tikka
                    </li>
                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                        Bhel Puri Chaat
                    </li>
                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                        Mini Masala Papad
                    </li>
                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                        Mini Aloo Tikki
                    </li>
                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                        Tandoori Haloumi
                    </li>
                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                        Paneer Tikka (Tandoori)
                    </li>
                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                        Chilli Mogo
                    </li>
                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                        Manchurian Gobi
                    </li>
                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                        Mini Chili Idly
                    </li>
                  </ul>
                </div>
                <!-- <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Classic Starters</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of classic_starters" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Street Food Starters</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of street_tandoori_starts" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Tandoori Starters</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of tandoori_starts" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                      </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Soup</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of soupsList" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                   </ul>
                </div> -->
             </div>
          </div>
          <div id="mobile2" class="container tab-pane fade"><br>
            <div class="container-fluid" style="display: flex;flex-wrap: wrap;">
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Non-Vegetarian</h4>
                   <ul class="list-group list-group-hover list-group-striped">

                    <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                       Mixed Grill


                   </li>
                   <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                       Cocktail Chicken Tikka

                   </li>
                   <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                       Mini Seekh Kebabs

                   </li>
                   <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                       Chicken Tikka (boneless)

                   </li>
                   <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                       Fried Massalla Fish (Salmon)
                   </li>
                   <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                       Tandoori Chicken Tikka (boneless)

                   </li>
                   <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                       Mini Chicken Samosa

                   </li>
                   <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                       Chicken Lollipops

                   </li>
                   <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                       Chicken Pakora

                   </li>
                   <li  class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">
                       Lamb Samosas

                   </li>

                 </ul>
                </div>
                <!-- <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Regional Non Veg</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of regional_non_veg" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Mains Rice</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of mains_rice" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                            {{value.description}}
                        </div>

                        <button type="button"  class="read-btn" (click)="showText()">
                            {{ isReadMore ? 'Read More': 'Read Less' }}
                          </button>
                        </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Biriyani Dishes</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of biriyani_dishes" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Family Pack Biryani</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of family_pack" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Bread Basket</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of bread_basket" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Regional Veg</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of regional_veg" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Mains Fry</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of mains_fry" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">Mains Noodles</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of mains_noodles" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                   </ul>
                </div>
                <div class="col-lg-4 p-3">
                   <h4 class="ml-3">South Indian Dishes</h4>
                   <ul class="list-group list-group-hover list-group-striped">
                      <li *ngFor="let value of south_india_dish" class="list-group-item"><img src="assets/img/pot.png" class="pot-img" alt="image">{{value.name}}<img src="assets/img/chilli.png"  class="" alt="image" *ngIf="value.spice === 'Low' "><img src="assets/img/2chill.png" class="" alt="image" *ngIf="value.spice === 'Medium' "><img src="assets/img/3chill.png" class="" alt="image" *ngIf="value.spice === 'High' ">
                         <button *ngIf="value.allergens"  type="button" class="btn btn-light position-relative"
                            tooltip="Spice: Low | medium | High; V: Suitable for Vegeterians;Allergens: 1.Celery | 2. Gluten | 3.Crustaceans | 4.Eggs | 5. Fish | 6.Lupin | 7.Milk | 8. Molluscs | 9. Mustard | 10. Nuts | 11. Peanuts | 12.Sesame Seeds | 13.Soya | 14.Sulphur Dioxide"
                            placement="right">
                         <i class="fa fa-info-circle" aria-hidden="true"></i>
                         <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light" style="color: darkgray;">
                         {{value.allergens}}
                         </span>
                         </button><img src="assets/img/veg_icon.png" *ngIf="value.veg_icon=== 'Yes' " width="10" alt="image">
                         <div class="desDiv">

                            <div class="menuDescription" [ngClass]="{'limitTextHeight': isReadMore}">
                                {{value.description}}
                            </div>

                            <button type="button"  class="read-btn" (click)="showText()">
                                {{ isReadMore ? 'Read More': 'Read Less' }}
                              </button>
                            </div>
                        </li>
                   </ul>
                </div> -->
             </div>
          </div>
         </div>
      </div>
 </div>
