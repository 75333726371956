<div class="categories-area pt-100 pb-50">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Order Catering</span>
            <h2>Place your Catering Order</h2>
        </div>
        <div class="courses-slides">
            <owl-carousel-o [options]="coursesSlides" >

                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/delivery/Group 353.png" alt="image" >
                       </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/delivery/Group 352.png" alt="image" >

                        </div>
                </ng-template>


                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/delivery/Group 351.png" alt="image" >
                       </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/delivery/event.png" alt="image" >
                       </div>
                </ng-template>


                <!-- <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/award/4.png" alt="image">
                       </div>
                </ng-template>


                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/award/5.png" alt="image">
                       </div>
                </ng-template>


                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/award/6.png" alt="image">
                       </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/award/7.png" alt="image">
                       </div>
                </ng-template> -->


            </owl-carousel-o>
        </div>

    </div>
</div>
