import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-leave-your-feed-back',
  templateUrl: './leave-your-feed-back.component.html',
  styleUrls: ['./leave-your-feed-back.component.scss']
})
export class LeaveYourFeedBackComponent implements OnInit {
    reactiveForm: UntypedFormGroup;
    currentRate = 0;
    nameinvalidError: boolean;
    messageinvalid: boolean;
    ratinginvalid: boolean;

  constructor(private http: HttpClient,
    private fb: UntypedFormBuilder,
    private router: Router) { }

  ngOnInit(): void {
    this.reactiveForm = this.fb.group({
        name:['',Validators.required],
        email:[''],
        role:[''],
        company:[''],
        rating:[null, Validators.required],
        feedback:['',Validators.required]
    });
  }

  bgImage = [
    {
        img: 'assets/img/register-shape.jpg'
    }
]

nameinvalid() {
    if(this.reactiveForm.get('name').value == "" || this.reactiveForm.get('name').value == undefined){
        this.nameinvalidError = true;
    } else {
        this.nameinvalidError = false;
    }
}

messageinvalidError() {
    if(this.reactiveForm.get('feedback').value == "" || this.reactiveForm.get('feedback').value == undefined){
        this.messageinvalid = true;
    } else {
        this.messageinvalid = false;
    }
}

ratinginvalidError() {
    if(this.reactiveForm.get('rating').value == "" || this.reactiveForm.get('rating').value == undefined || this.reactiveForm.get('rating').value == null){
        this.ratinginvalid = true;
    } else {
        this.ratinginvalid = false;
    }
}

onRateChange(rating :number) {
    this.ratinginvalid = false;
}

submitData(){
    if(this.reactiveForm.invalid) {
        this.nameinvalid();
        this.messageinvalidError();
        this.ratinginvalidError();
        console.log("error")
        // this.router.navigateByUrl('/error');
    } else {
        console.log("Success");
        console.log(this.reactiveForm.value)
        const feedbackdata = this.http.post('https://vt8cp662fg.execute-api.eu-west-2.amazonaws.com/test/feedback/create',this.reactiveForm.value)
        const emailVerification = this.http.post('https://vt8cp662fg.execute-api.eu-west-2.amazonaws.com/test/feedback/thankyouemail',this.reactiveForm.value)

        forkJoin([feedbackdata,emailVerification]).subscribe(res =>{
            return res;
        })
        this.router.navigateByUrl('/thank_you');
    }
}

dataReset() {
    this.reactiveForm.reset();
    this.ratinginvalid = false;
    this.nameinvalidError = false;
    this.messageinvalid = false;
}

}
