<div class="container">
    <div class="section-title">
        <span class="sub-title">News and Blogs</span>
        <h2>Our Latest Publications</h2>
        <!-- <p>We always give extra care to our student's skills improvements and feel excited to share our latest research and learnings!</p> -->
    </div>
    <div class="row justify-content-center mt-4">
        <div class="col-lg-12 col-md-12" >
            <div class="single-blog-post">
                <div class="post-image">
                    <div  class="d-block">
                        <img src={{blogDetail.postImageUrl}} alt="image" class="blog_image">
                    </div>
                </div>
                <div class="post-author d-flex align-items-center mt-5">
                    <!-- <img src="assets/img/user1.png" class="rounded-circle" style="list-style-type: none;" alt="image"> -->

                    <span >by {{blogDetail.postAuthor}}</span>
                    <span  style="padding-left: 20px;"><i class='flaticon-calendar' ></i> {{blogDetail.postDate | date}}</span>
                </div>

                <div class="title"> <h3><a >{{blogDetail.postTitle}}</a></h3></div>

                <div class="post-content">

                    <div [innerHtml]="postBodydata.postBody">{{postBodydata.postBody}}</div>

                </div>

                <div class="comments-area">

                    <!-- <ol class="comment-list">
                        <li class="comment">

                        </li>

                    </ol> -->
                    <div class="comment-respond">
                        <div>
                            <div class="comment-body"  *ngFor="let i of blog_comments">
                                <footer class="comment-meta">
                                    <img src="../../../../assets/img/blog/2-modified.png" class="avatar" alt="image">
                                    <span class="comment-author vcard">

                                        <h5 class="fn">{{i.commentAuthor.name}}</h5> <span>{{i.commentTime | date}}</span>
                                    </span>

                                    <!-- <div class="comment-metadata">
                                        <a ><span>{{i.commentTime | date}}</span></a>
                                    </div> -->
                                </footer>

                                <div class="comment-content">
                                    <p>{{i.commentContent}}</p>
                                </div>

                                <div class="reply">

                                    <ng-template #content let-modal>
                                        <div class="modal-header">
                                            <h4 class="modal-title"
                                                id="modal-basic-title">
                                                Reply
                                            </h4>
                                            <button type="button" class="close"
                                                    aria-label="Close" (click)=
                                                    "modal.dismiss('Cross click')">

                                                <span aria-hidden="true">
                                                    ×
                                                </span>
                                            </button>
                                        </div>

                                        <div class="modal-body">
                                            <form  [formGroup]="reply">
                                                <div class="comment-form-reply">
                                                    <span>Reply to  </span> <label style="font-weight: bolder;"> {{i.commentAuthor.name}}</label>

                                                    <div class="comment-form-author">

                                                        <input  (keyup)="replynameinvalid()"  type="text" id="author" name="author" required="required" placeholder="Name" formControlName="name" class="form-control">
                                                        <div class="alert alert-danger" *ngIf="rnameinvalidError">
                                                            <div>Full Name is required.</div>
                                                        </div>
                                                    </div>

                                                    <div class="comment-form-email">

                                                        <input  type="email" id="email" name="email" required="required" placeholder="Email" formControlName="email" class="form-control">
                                                        <div class="alert alert-danger" *ngIf="rsubmitted && rf.email.errors "  (keyup)="replyemailinvalidError()">
                                                            <div *ngIf="rf.email.errors.required">Email is required</div>
                                                            <div *ngIf="rf.email.errors.email">Email must be a valid email address</div>
                                                        </div>
                                                    </div>


                                                    <input (keyup)="replyCommentInvalid()" type="comment" id="reply" name="reply" placeholder="Comment Reply " formControlName="commentReply" class="form-control">
                                                    <div class="alert alert-danger" *ngIf="commentinvalid">
                                                        <div>Comment Reply is required.</div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div class="modal-footer">
                                            <button type="button"
                                                class="btn btn-outline-dark"
                                                (click)="submitReplyData() ;"
                                                >
                                                Post a Reply
                                            </button>
                                        </div>
                                      </ng-template>

                                      <a (click)="open(content)" (click)="cid(i.id)" class="reply-button">Reply</a>
                                </div>
                                <!-- reply section -->
                               <div *ngFor="let reps of  jsobj(i.commentReplies)">
                                <ol class="leftborder">
                                    <li class="reply-list">
                                        <div >
                                            <footer class="comment-meta">
                                                <img src="../../../../assets/img/blog/2-modified.png" class="avatar" alt="image">
                                                <span class="comment-author vcard">

                                                    <h5 class="fn" style="display: inline-block;">{{reps.commentAuthor.name}}</h5>
                                                </span> <span>{{reps.commentTime | date}}</span>

                                                <!-- <div class="comment-metadata">
                                                    <a ></a>
                                                </div> -->
                                            </footer>

                                            <div class="comment-content">
                                                <p>{{reps.commentContent}}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                                 <!-- reply section -->
                            </div>
                        </div>
                    </div>

                    <div class="comment-respond">
                        <div>
                            <h3 class="comment-reply-title">Leave a Reply</h3>

                            <form class="comment-form"  [formGroup]="blogForm">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked
                                    <span class="required">*</span>
                                </p>

                                <div class="comment-form-author">

                                    <input (keyup)="nameinvalid()"  type="text" id="author" name="author" required="required" formControlName="name" class="form-control" placeholder="Name">
                                    <div class="alert alert-danger" *ngIf="nameinvalidError">
                                        <div>Full Name is required.</div>
                                    </div>
                                </div>

                                <div class="comment-form-email">

                                    <input (keyup)="emailinvalidError()" type="email" id="email" name="email" required="required" formControlName="email" class="form-control"
                                    placeholder="Email"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div class="alert alert-danger" *ngIf="submitted && f.email.errors ">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>

                                <div class="comment-form-comment">

                                    <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required" formControlName="commentContent" class="form-control" placeholder="Comment"></textarea>
                                </div>

                                <p class="form-submit mt-3">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Post A Comment" (click)="submitData()">

                                </p>
                            </form>
    </div>
                     </div>
                </div>
            </div>
        </div>
    </div>

    <app-services-buttons-block></app-services-buttons-block>

</div>
