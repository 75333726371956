import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-festival-and-social-events',
  templateUrl: './festival-and-social-events.component.html',
  styleUrls: ['./festival-and-social-events.component.scss']
})
export class FestivalAndSocialEventsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
