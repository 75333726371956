<div class="about-area-three ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">Catering with passion</span>
                    <h2>Indian Aroma Caterers</h2>
                    <p>
                        Our catering services are very unique.
                        We provide a truly authentic Indian food for all occasions.
                        We have an excellent team of trained chefs and staff members who will work with you in understanding your requirements, giving you options and delivering the best Indian food. Our customers have been extremely happy with our services and the wide range of menu we offer.




                    </p>
                    <p><strong>We have the top food hygiene rating that we are proud of and are continuing to uphold. We are one of the very few Indian restaurants in the UK who has adopted recyclable packaging and have fully eliminated plastic materials.</strong></p>
                    <a routerLink="/event-enquiry" class="default-btn"><i class="flaticon-heart"></i>Get a Free Quote<span></span></a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-img">
                    <div class="image">
                        <img src="assets/img/about/about1.png" class="aroma" alt="image">
                        <img src="assets/img/about/gulabjamun.png" class="gulab" alt="image">
                    </div>
                    <div class="shape17"><img src="assets/img/shape16.png" alt="image"></div>
                    <div class="shape18"><img src="assets/img/shape17.png" alt="image"></div>
                    <div class="shape19"><img src="assets/img/shape18.png" alt="image"></div>
                    <div class="shape20"><img src="assets/img/shape19.png" alt="image"></div>
                    <div class="shape21"><img src="assets/img/shape20.png" alt="image"></div>
                    <!-- <div class="shape22"><img src="assets/img/shape21.png" alt="image"></div> -->
                    <div class="shape23"><img src="assets/img/shape22.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
</div>
