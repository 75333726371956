<div class="funfacts-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <h3><span [countUp]="1000">00</span>+</h3>
                    <p>Total Caterings</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <h3><span [countUp]="98">00</span>%</h3>
                    <p>Repeat Customers</p>
                </div>
            </div>
        </div>
    </div>
</div>
