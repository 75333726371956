<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Leave Your Feedback</li>
            </ul>
            <h2>Leave Your Feedback</h2>
        </div>
    </div>
    <
</div>

<div class="profile-area pt-5 pb-70">
    <div class="container">
        <div class="col-lg-12 col-md-12">
            <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                <h2>Leave Your Feedback
                </h2>
                <p>Please fill out the following online form and a member of our team will call you as soon as possible to discuss the Food Menu and any other requirements.</p>
                <form [formGroup]="reactiveForm">
                    <div class="row">
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input name="name" formControlName="name" (keyup)="nameinvalid()"class="form-control" placeholder="Your Full Name*">
                                <div class="alert alert-danger" *ngIf="nameinvalidError">
                                    <div>Full Name is required.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group">
                                <input name="email" type="email" formControlName="email" class="form-control" placeholder="Email">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input name="role" type="text" formControlName="role" class="form-control" placeholder="Role">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <input name="company" type="text" formControlName="company" class="form-control" placeholder="Company">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <ngb-rating [max]="5" (rateChange)="onRateChange($event)" [(rate)]="currentRate" [readonly]="false" formControlName="rating" class="star" ></ngb-rating>
                                <div class="alert alert-danger" *ngIf="ratinginvalid">Rating is required.</div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="feedback" (keyup)="messageinvalidError()" formControlName="feedback" class="form-control" cols="30" rows="5" placeholder="Your Feedback...*"></textarea>
                                <div class="alert alert-danger" *ngIf="messageinvalid">Feedback is required.</div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 d-flex">
                            <button type="submit" class="default-btn" (click)="submitData()">Send Message<span></span></button>
                            <button class="default-btn" (click)="dataReset()">Reset<span></span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
