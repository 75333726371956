<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Customers - By Location</li>
            </ul>
            <h2>Customers - By Location</h2>
        </div>
    </div>
    <
</div>

<div class="events-details-area pb-100">

    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">

                <div class="events-details-info">
                    <ul class="info">
                        <li>
                            <div class="d-flex justify-content-between align-items-center">

                                Our Catering Customers - By Location
                                <div class="payment-method">
                                    <img src="assets/img/pot-iac.png" alt="image">
                                    <img src="assets/img/pot-iac.png" alt="image">
                                    <img src="assets/img/pot-iac.png" alt="image">
                                    <img src="assets/img/pot-iac.png" alt="image">
                                </div>
                            </div>
                        </li>
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center data-new">
                                <span class="split-item" *ngFor="let data of locationData.customers_dev">{{data.fields.items}}</span>
                            </div>
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a href="https://indianaroma.co.uk/" class="default-btn" target="_blank"><i class="flaticon-healthy-food"></i>Our Restaurant<span></span></a>
                        <p>Visit us today.</p>
                    </div>

                    <div class="events-share">
                        <div class="share-info">
                            <span>Social Media <i class="flaticon-webinar"></i></span>
                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/IndianAromaGantsHill/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://www.instagram.com/indian_aroma_london/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" class="d-block" target="_blank"><i class="bx bxl-youtube"></i></a></li>

                                <li><a href="https://wa.me/+447916507777" class="d-block" target="_blank"><i class="bx bxl-whatsapp"></i></a></li>


                                <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-google-plus'></i></a></li> -->
                                <li><a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9?source=g.page.m.we._" class="d-block" target="_blank"><i class="bx bxl-google"></i></a></li>

                            </ul>
                        </div>
                    </div>
                </div>


            </div>
            <div class="col-lg-6 col-md-12">
                <div class="events-details-header">
                    <ul>
                        <li><i class="bx bx-food-menu"></i>Catering Orders</li>

                        <li><i class="bx bxs-calendar-event"></i>Several Occassions</li>
                        <li><i class="bx bx-map"></i>We deliver catering within a 50-mile radius of IG2 6UF.</li>
                    </ul>
                </div>
                <div class="events-details-location">
                    <div id="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.494421795278!2d0.0625836157725634!3d51.57750127964681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a6ea02a68f01%3A0x3f9f7a97f7ff7b5a!2s33%20Woodford%20Ave%2C%20Gants%20Hill%2C%20Ilford%20IG2%206UF!5e0!3m2!1sen!2suk!4v1645459915806!5m2!1sen!2suk"></iframe>
                    </div>
                </div>
             <!--    <div class="events-details-desc">
                    <h3>About The Event</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <h3>Where the event?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <h3>Who this event is for?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div> -->
            </div>

        </div>
    </div>
</div>

