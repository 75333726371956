<div class="categories-area pt-100 pb-50">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">To Our Awesome Customers - Thank you !</span>
            <h2>Curry Life Award Winner 2022</h2>
            <p> We place the utmost importance on producing high-quality services for our customers, and winning an award has never been a goal. However, there are occasions when the rewards make you happy.</p>
        </div>
        <div class="courses-slides">
            <owl-carousel-o [options]="coursesSlides">
                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/award/1.png" alt="image" width="">

                        </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/award/2.png" alt="image">
                       </div>
                </ng-template>


                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/award/3.png" alt="image">
                       </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/award/4.png" alt="image">
                       </div>
                </ng-template>


                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/award/5.png" alt="image">
                       </div>
                </ng-template>


                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/award/6.png" alt="image">
                       </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-categories-box-award">
                        <img src="../../../../../assets/img/award/7.png" alt="image">
                       </div>
                </ng-template>


            </owl-carousel-o>
        </div>
        <div class="courses-info">
            <p>We received prestigious awards after years of hard work. As always, we thank our staff's dedication and hard work, the love and support of our friends and family, and the support of our customers.</p>
        </div>
    </div>
</div>
