<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Venues</li>
            </ul>
            <h2>Venues</h2>
        </div>
    </div>
</div>

<div class="premium-access-area ">
    <div class="container">
    <div class="premium-access-content">

        <h2>Award-Winning, Bespoke Indian Caterers</h2>
        <p>We have catered parties, events, and celebrations in a variety of settings, including family homes and filming locations, hotels, community halls, leisure centres, golf clubs, parks, historical sites, and more.</p>
        <p>These areas encompass Watford, Dartford, Welling, Sidcup, Belvedere, Orpington, Bromley, Tonbridge, Sevenoaks, Chelmsford, Bedford, Milton Keynes, Wembley Central, Upton Park, Romford, and beyond.</p>

    </div>
</div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div>

<div class="advisor-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="../../../../assets/img/venue/2.jpg" alt="image">

                    </div>
                    <div class="advisor-content">

                        <h3><a>Hilton London Watford</a></h3>
                        <span>110 Guests - Corporate Catering</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="../../../../assets/img/venue/1.jpg" alt="image">


                    </div>
                    <div class="advisor-content">
                        <h3><a >Marriott Bexleyheath, Kent</a></h3>
                        <span>150 Guests - Birthday Party</span>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="assets/img/advisor/img6.jpg" alt="image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="advisor-content">
                        <h3><a routerLink="/profile">Alister Cock</a></h3>
                        <span>QA Project Expert</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="assets/img/advisor/img7.jpg" alt="image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="advisor-content">
                        <h3><a routerLink="/profile">Lina Ninja</a></h3>
                        <span>QA Project Expert</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="assets/img/advisor/img8.jpg" alt="image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="advisor-content">
                        <h3><a routerLink="/profile">Chris Evans</a></h3>
                        <span>Python Expert</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="assets/img/advisor/img9.jpg" alt="image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="advisor-content">
                        <h3><a routerLink="/profile">Scarlett Johansson</a></h3>
                        <span>Photoshop Expert</span>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>


