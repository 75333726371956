<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Social Policy & Responsibility</li>
            </ul>
            <h2>Social Policy & Responsibility</h2>
        </div>
    </div>
    <
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="privacy-policy-content">

                    <h3>We recognise the importance of corporate social responsibility and strive to operate in an ethical and environmentally conscious manner:</h3>

                    <ul>

<li>Internal audits and checks are conducted on a regular basis to guarantee that we are always in compliance with health and safety requirements.
</li>
<li>Sales growth and customer satisfaction have benefited from quality management and investment in our personnel.
</li>
<li>We are devoted to providing consistent products and services, to working in a motivated and equal opportunity workplace, and to conserving energy and minimising waste.
</li>
<li>By adhering to industry norms, we have won consumer confidence in our products and services.
</li>
<li>We also help local communities by generating funds for charities, schools, initiatives, and religious institutions.
</li>
<li>We are a well-known company not just in the town, but also across the South East. Because of our reputation and track record, our clients, suppliers, and venues may feel safe doing business with us.
</li>
<li>We are dedicated to ensuring that our main business of providing commercial value to clients is carried out in a sustainable manner.
    <br/>We are dedicated to the following: <br/>
</li>



<li>
    Adherence to all applicable environmental laws, regulations, and established codes of practise
</li>

<li>Protecting the environment by attempting to eliminate or reduce our contribution to pollution of the land, air, and water</li>
<li>Attempting to minimise waste and maximise the efficient use of materials and resources.</li>
<li>Across all departments, we recycle as much as possible.</li>
<li>All garbage must be managed and disposed of responsibly.</li>
<li>Providing training for our employees so that we can all operate in compliance with this policy and in an environmentally conscious culture, and disclosing our environmental performance to our employees on a regular basis.</li>


                    </ul>





            </div>

        </div>
    </div>
</div>

