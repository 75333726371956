import { Component, OnInit } from '@angular/core';
import { CustomerLocationsService } from './customer-locations.service';

@Component({
  selector: 'app-customer-locations',
  templateUrl: './customer-locations.component.html',
  styleUrls: ['./customer-locations.component.scss']
})
export class CustomerLocationsComponent implements OnInit {
  locationData: any = [];

  constructor(private customerLocationsService:CustomerLocationsService) { }

  ngOnInit(): void {
    this.customerLocationsService.getLocation().subscribe(res =>{
        this.locationData = res;
    })
  }

}
