import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'underscoreRemove'
})
export class UnderscoreRemovePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        return value.replace(/_/g, " ");
      }

}
