import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-wedding-combos',
  templateUrl: './wedding-combos.component.html',
  styleUrls: ['./wedding-combos.component.scss']
})
export class WeddingCombosComponent implements OnInit {
    @HostListener('window:scroll', ['$event'])
    
    checkScroll() {
      this.isSticky = window.pageYOffset >= 250;
    }
    isSticky: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

}
