<div class="funfacts-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <p><a routerLink="/menus" class="default-btn"><i class="flaticon-verify"></i>Popular Menus<span></span></a></p>
                    <p>Popular Combos</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                   <p><a routerLink="/our-full-menu" class="default-btn"><i class="flaticon-healthy-food"></i>Menu<span></span></a></p>
                    <p>View Full Menu</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <p><a routerLink="/our-gallery" class="default-btn"><i class="flaticon-artist"></i>Gallery<span></span></a></p>
                    <p>View Our Gallery</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="assets/img/funfacts-shape2.png" alt="image">
                    <p><a routerLink="/event-enquiry" class="default-btn"><i class="flaticon-calendar"></i>Contact<span></span></a></p>
                    <p>Get a Free Quote</p>
                </div>
            </div>

        </div>
    </div>
</div>

