import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { MenuListServiceService } from '../menu-list-service.service';

@Component({
  selector: 'app-live-station-menu-list',
  templateUrl: './live-station-menu-list.component.html',
  styleUrls: ['./live-station-menu-list.component.scss'],
  providers:[MenuListServiceService]
})
export class LiveStationMenuListComponent implements OnInit {
    @HostListener('window:scroll', ['$event'])

    checkScroll() {
      this.isSticky = window.pageYOffset >= 250;
    }
    isSticky: boolean = false;
    data: any = [];
    groupArr: any;
    starters: any;
    classic_starters: any;
    south_india_dish: any;
    street_tandoori_starts: any;
    kids_meal: any;
    desserts: any;
    extra_kids: any;
    extra_pickle: any;
    extra: any;

    constructor(private http: HttpClient,
                private menuListServiceService:MenuListServiceService) { }

    ngOnInit(): void {
        this.menuListServiceService.getlivemenu().subscribe(res=>{
          this.data= res;
          this.starters = this.data.filter(x => x.menu === "Starters");
          this.classic_starters = this.data.filter(x => x.menu === "Classic_Starters");
          this.south_india_dish = this.data.filter(x => x.menu === "South_Indian_Dishes");
          this.street_tandoori_starts = this.data.filter(x => x.menu === "Street_Food_Starters");
          this.kids_meal = this.data.filter(x => x.menu === "Kids_Meal");
          this.groupArr = this.data.reduce((r,{menu})=>{
            if(!r.some(o=>o.menu==menu)){
              r.push({menu,groupItem:this.data.filter(v=>v.menu==menu)});
        }
        return r;
        },[]);
      });
      this.getDesserts();
      this.getmenu();
    }

    getDesserts(){
        this.menuListServiceService.getfullmenu().subscribe(res=>{
            this.data = res;
            this.desserts = this.data.filter(x => x.menu === "Desserts");
        })
      }

      getmenu() {
        this.menuListServiceService.getExtraList().subscribe(res=>{
            this.data = res;
            this.extra_kids = this.data.filter(x => x.menu === "Extras_Kids");
            this.extra_pickle = this.data.filter(x => x.menu === "Extras_Pickles");
            this.extra = this.data.filter(x => x.menu === "Extras");
        })
      }

      scrollToTop(): void {
        window.scrollTo(0, 0);
        }

}
