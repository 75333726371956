<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Sitemap</li>
            </ul>
            <h2>Sitemap</h2>
        </div>
    </div>

</div>

<div class="profile-area pt-5 pb-70">
    <div class="container">
        <ul>
            <li class="nav-item cursor"><a routerLink="/" class="nav-link test">Home</a></li>
            <li class="nav-item cursor"><a routerLink="/catering-services" class="nav-link test">Catering Services</a>
                <ul>
                    <li class="nav-item"><a routerLink="/live-dosa-station" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Live Dosa Station</a></li>
                    <li class="nav-item"><a routerLink="/family-gatherings" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Family Gatherings</a></li>
                    <li class="nav-item"><a routerLink="/festival-and-events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Festival & Social Events</a></li>
                    <li class="nav-item"><a routerLink="/weddings" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Weddings</a></li>
                    <li class="nav-item"><a routerLink="/film-crew-catering" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Film Crew Catering</a></li>
                    <li class="nav-item"><a routerLink="/corporate_events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Corporate Events</a></li>
                    <li class="nav-item"><a routerLink="/sports_events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sports Events</a></li>
                    <li class="nav-item"><a routerLink="/charity_fundraising_events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Charity Fundraising Events</a></li>
                </ul>
            </li>
            <li class="nav-item cursor"><a href="/menus" class="nav-link">Menus</a>
                <ul>
                    <li class="nav-item"><a routerLink="/festivel-combo" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Festive Combos</a></li>
                    <li class="nav-item"><a routerLink="/best-selling-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Best Selling Combos</a></li>
                    <li class="nav-item"><a routerLink="/veg-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pure Veg Combos</a></li>
                    <li class="nav-item"><a routerLink="/veg-and-nonveg-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Veg / Non Veg Combos</a></li>
                    <li class="nav-item"><a routerLink="/breakfast-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Breakfast Combos</a></li>
                    <li class="nav-item"><a routerLink="/lunch-or-dinner-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Lunch/Dinner Combos</a></li>
                    <li class="nav-item"><a routerLink="/wedding-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wedding Combos</a></li>


                    <li class="nav-item"><a routerLink="/canapes-menu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Canapés Menu</a></li>
                    <li class="nav-item"><a routerLink="/live-stations" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Live Stations Menu</a></li>
                    <li class="nav-item"><a routerLink="/pure-vegmenu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pure Veg Menu</a></li>
                    <li class="nav-item"><a routerLink="/our-full-menu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Our Full Menu</a></li>
                    <li class="nav-item"><a routerLink="/cutomize-menu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Customise Menu</a></li>

                </ul>
            </li>

            <li class="nav-item cursor"><a routerLink="/our-gallery" class="nav-link">Gallery</a></li>


            <li class="nav-item cursor"><a routerLink="/customer-locations"  class="nav-link">Reviews</a>
                <ul>
                    <li class="nav-item"><a routerLink="/customer-locations" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">By Location</a></li>
                    <li class="nav-item"><a routerLink="/reviews" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Reviews</a></li>
                    <li class="nav-item"><a routerLink="/leave_your_feedback" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Leave Your Feedback</a></li>
                    <li class="nav-item"><a routerLink="/subscribeNewsletter" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Our Newsletter</a></li>
                </ul>
            </li>

            <li class="nav-item cursor"><a routerLink="/venues" class="nav-link">Venues</a></li>

            <li class="nav-item cursor"><a routerLink="/about-us" class="nav-link">About Us</a>
                <ul>
                    <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Our Story</a></li>
                    <li class="nav-item"><a href="https://indianaroma.co.uk/" class="nav-link">Our Restaurant</a></li>
                    <!-- <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">FAQ</a></li> -->
                   <!-- <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog</a></li> -->
                    <li class="nav-item"><a routerLink="/audit-and-certificates" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Audits & Certificates       </a></li>
                    <li class="nav-item"><a routerLink="/scp" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Social Policy & Responsibility      </a></li>

                </ul>
            </li>
        </ul>
    </div>
</div>


