<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Cookie Policy</li>
            </ul>
            <h2>Cookie Policy</h2>
        </div>
    </div>
    <
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="privacy-policy-content">
                    <img src="assets/img/cookie-policy.png" alt="image">
                    <p><i>Important Information About The Cookies We Use On Our Website</i></p>
                    <h3>1. GENERAL</h3>
                    <blockquote class="blockquote">
                        <p>Just like almost all other websites, Indian Aroma uses cookies to make our website a great experience.</p>
                        <p>A cookie, also known as an HTTP cookie, web cookie, or browser cookie, is a piece of data stored by a website within a browser (eg: Internet Explorer, Google Chrome, Safari, Firefox, etc.) and then subsequently sent back to the same website by the browser. Cookies were designed to be a reliable mechanism for websites to remember things that a browser had done there in the past.</p>
                        <p>Although cookies cannot carry viruses and cannot install malware on the host computer, tracking cookies, and especially third-party tracking cookies, are commonly used as way to compile long-term records of individuals’ browsing histories – a major privacy concern that prompted European and US law makers to take action.</p>
                    </blockquote>
                    <h3>2. COOKIE TYPES</h3>
                    <p>There are basically four types of cookies a website can create within your browser:</p>
                    <ol>
                        <li>Strictly necessary cookies
                            <p>let you move around the website and use essential features like secure areas, shopping baskets and online billing. These cookies don’t gather any information about you that could be used for marketing or remembering where you’ve been on the internet.</p>
                        </li>
                        <li>Performance cookies
                            <p>collect information about how you use our website e.g. which pages you visit, and if you experience any errors. These cookies don\’t collect any information that could identify you and all the information collected is anonymous.</p>
                        </li>
                        <li>Functionality cookies
                            <p>used to provide services or to remember settings to improve your visit.</p>
                        </li>
                        <li>Targeting cookies
                            <p>linked to services provided by third parties, such as ‘Like’ buttons and ‘Share’ buttons. The third party provides these services in return for recognising that you have visited our website.</p>
                        </li>
                    </ol>
                    <h3>3. ABOUT OUR COOKIES</h3>
                    <p>Our website only uses only two cookies and this page explains how we use them, in accordance with the EU Cookie Directive. You should read this along with our privacy policy.</p>
                    <ul>
                        <li>Cookie name: myid
                            <p>This is a strictly necessary cookie containing a long set of randomly-generated characters. It is created when you first visit the site and stores items that you place in your order prior to logging in and acts as your personal id through the checkout process to ensure that you are who you say you are. It is an anonymous session cookie which does not retrieve any information, personal or otherwise, from your computer. If you do not wish to accept this cookie, then you must leave our website now, since without it you cannot make a purchase. To continue you must click or tap the “Accept” button if you haven’t already done so. By doing so you consent to us placing this cookie on your system. This cookie expires immediately when you quit your browser.</p>
                        </li>
                        <li>Cookie name: acceptmyid
                            <p>This is a functionality cookie which remembers that you have agreed to accept the use of our cookies. This cookie allows you to return to our website and use it without having to repeatedly click or tap the “Agree” button. This cookie expires 1 year after it is first set.</p>
                        </li>
                    </ul>
                    <h3>4. FURTHER INFORMATION</h3>
                    <p>For further information on cookies please refer to&nbsp;<a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener">www.allaboutcookies.org</a>.</p>
            </div>

        </div>
    </div>
</div>
