import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuListServiceService {

  constructor(private http: HttpClient) { }

  getfullmenu() {
    return this.http.get('../../../assets/menu-data/full menu.json');
  }

  getvegList() {
      return this.http.get('../../../assets/menu-data/veg.json');
  }

  getlivemenu() {
      return this.http.get('../../../assets/menu-data/live.json');
  }

  getmenudata(){
      return this.http.get('../../../assets/menu-data/Mains.json');
  }

  getStartersmenu(){
      return this.http.get('../../../assets/menu-data/Starters.json')
  }

  getdesserts() {
    return this.http.get('../../../assets/menu-data/Desserts.json')
  }

  getExtraList() {
    return this.http.get('../../../assets/menu-data/Extras.json')
  }
  getSoupList() {
    return this.http.get('../../../assets/menu-data/Soups.json')
  }
}
