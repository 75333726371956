import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-live-dosa-station',
  templateUrl: './live-dosa-station.component.html',
  styleUrls: ['./live-dosa-station.component.scss']
})
export class LiveDosaStationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  

}
