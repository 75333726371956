<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="../../../../assets/img/logonh.svg" alt="logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mainMenu">
                    <li class="nav-item cursor" (click)="showPhase(1)" [class.phase-active]="activeButton === 1"><a routerLink="/" class="nav-link test"> <i class="fa fa-home mr-1 home-icon"></i>Home</a></li>

                    <li class="nav-item cursor" (click)="showPhase(2)" [class.phase-active]="activeButton === 2"><a routerLink="/catering-services" class="nav-link test">Catering Services <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/live-dosa-station" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Live Dosa Station</a></li>
                            <li class="nav-item"><a routerLink="/family-gatherings" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Family Gatherings</a></li>
                            <li class="nav-item"><a routerLink="/festival-and-events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Festival & Social Events</a></li>
                            <li class="nav-item"><a routerLink="/weddings" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Weddings</a></li>
                            <li class="nav-item"><a routerLink="/film-crew-catering" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Film Crew Catering</a></li>
                            <li class="nav-item"><a routerLink="/corporate_events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Corporate Events</a></li>
                            <li class="nav-item"><a routerLink="/sports_events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sports Events</a></li>
                            <li class="nav-item"><a routerLink="/charity_fundraising_events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Charity Fundraising Events</a></li>
                        </ul>
                    </li>

                    <li class="nav-item cursor" (click)="showPhase(3)" [class.phase-active]="activeButton === 3"><a routerLink="/menus" class="nav-link">Menus<i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">

                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Popular Catering Combos <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/festivel-combo" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Festive Combos</a></li>
                                    <li class="nav-item"><a routerLink="/best-selling-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Best Selling Combos</a></li>
                                    <li class="nav-item"><a routerLink="/veg-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pure Veg Combos</a></li>
                                    <li class="nav-item"><a routerLink="/veg-and-nonveg-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Veg / Non Veg Combos</a></li>
                                    <li class="nav-item"><a routerLink="/breakfast-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Breakfast Combos</a></li>
                                    <li class="nav-item"><a routerLink="/lunch-or-dinner-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Lunch/Dinner Combos</a></li>
                                    <li class="nav-item"><a routerLink="/wedding-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wedding Combos</a></li>

                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/canapes-menu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Canapés Menu</a></li>
                            <li class="nav-item"><a routerLink="/live-stations" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Live Stations Menu</a></li>

                            <li class="nav-item"><a routerLink="/pure-vegmenu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pure Veg Menu</a></li>
                            <li class="nav-item"><a routerLink="/our-full-menu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Our Full Menu</a></li>
                            <li class="nav-item"><a routerLink="/cutomize-menu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Customise Menu</a></li>
                        </ul>
                    </li>

                    <li class="nav-item cursor" (click)="showPhase(4)" [class.phase-active]="activeButton === 4"><a routerLink="/our-gallery" class="nav-link">Gallery</a></li>


                    <li class="nav-item cursor" (click)="showPhase(5)" [class.phase-active]="activeButton === 5"><a routerLink="/reviews" class="nav-link">Reviews<i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/customer-locations" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">By Location</a></li>
                            <li class="nav-item"><a routerLink="/reviews" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Reviews</a></li>
                            <li class="nav-item"><a routerLink="/leave_your_feedback" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Leave Your Feedback</a></li>
                            <li class="nav-item"><a routerLink="/subscribeNewsletter" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Our Newsletter</a></li>
                        </ul>
                    </li>
                    <li class="nav-item cursor" (click)="showPhase(6)" [class.phase-active]="activeButton === 6"><a routerLink="/venues" class="nav-link">Venues</a></li>

                    <li class="nav-item cursor" (click)="showPhase(7)" [class.phase-active]="activeButton === 7"><a routerLink="/about-us" class="nav-link">About Us<i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Our Story</a></li>
                            <li class="nav-item"><a href="https://indianaroma.co.uk/" class="nav-link" target="_blank">Our Restaurant</a></li>
                            <!-- <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">FAQ</a></li> -->
                            <li class="nav-item"><a href="https://www.blog-indianaroma.co.uk/" class="nav-link" target="_blank">Blog</a></li> 
                            <li class="nav-item"><a routerLink="/audit-and-certificates" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Audits & Certificates       </a></li>
                            <li class="nav-item"><a routerLink="/scp" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Social Policy & Responsibility      </a></li>

                        </ul>
                    </li>


                </ul>

                <ul class="navbar-nav for-responsive">
                    <li class="nav-item" (click)="showPhase(1)" [class.phase-active]="activeButton === 1"><a routerLink="/" class="nav-link" (click)="toggleClass()"> <i class="fa fa-home mb-1"></i>Home</a></li>

                    <li class="nav-item" (click)="showPhase(2)" [class.phase-active]="activeButton === 2"><a routerLink="/catering-services" class="nav-link" (click)="toggleClass()">Catering Services <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/live-dosa-station" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Live Dosa Station</a></li>
                            <li class="nav-item"><a routerLink="/family-gatherings" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Family Gatherings</a></li>
                            <li class="nav-item"><a routerLink="/festival-and-events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Festival & Social Events</a></li>
                            <li class="nav-item"><a routerLink="/weddings" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Weddings</a></li>
                            <li class="nav-item"><a routerLink="/film-crew-catering" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Film Crew Catering</a></li>
                            <li class="nav-item"><a routerLink="/corporate_events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Corporate Events</a></li>
                            <li class="nav-item"><a routerLink="/sports_events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Sports Events</a></li>
                            <li class="nav-item"><a routerLink="/charity_fundraising_events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Charity Fundraising Events</a></li>
                        </ul>
                    </li>

                    <li class="nav-item" (click)="showPhase(3)" [class.phase-active]="activeButton === 3"><a routerLink="/menus" class="nav-link" (click)="toggleClass()">Menus<i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link" (click)="toggleClass()">Popular Catering Combos <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/festivel-combo" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Festive Combos</a></li>
                                    <li class="nav-item"><a routerLink="/best-selling-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Best Selling Combos</a></li>
                                    <li class="nav-item"><a routerLink="/veg-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Pure Veg Combos</a></li>
                                    <li class="nav-item"><a routerLink="/veg-and-nonveg-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Veg / Non Veg Combos</a></li>
                                    <li class="nav-item"><a routerLink="/breakfast-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Breakfast Combos</a></li>
                                    <li class="nav-item"><a routerLink="/lunch-or-dinner-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Lunch/Dinner Combos</a></li>
                                    <li class="nav-item"><a routerLink="/wedding-combos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Wedding Combos</a></li>

                                </ul>
                            </li>

                            <li class="nav-item"><a routerLink="/canapes-menu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Canapés Menu</a></li>
                            <li class="nav-item"><a routerLink="/live-stations" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Live Stations Menu</a></li>
                            <li class="nav-item"><a routerLink="/pure-vegmenu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Pure Veg Menu</a></li>
                            <li class="nav-item"><a routerLink="/our-full-menu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Our Full Menu</a></li>
                            <li class="nav-item"><a routerLink="/cutomize-menu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Customise Menu</a></li>
                        </ul>
                    </li>

                    <li class="nav-item" (click)="showPhase(4)" [class.phase-active]="activeButton === 4"><a routerLink="/our-gallery" class="nav-link" (click)="toggleClass()">Gallery</a></li>


                    <li class="nav-item" (click)="showPhase(5)" [class.phase-active]="activeButton === 5"><a routerLink="/reviews" class="nav-link" (click)="toggleClass()">Reviews<i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/customer-locations" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">By Location</a></li>
                            <li class="nav-item"><a routerLink="/reviews" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"(click)="toggleClass()">Reviews</a></li>
                            <li class="nav-item"><a routerLink="/leave_your_feedback" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"(click)="toggleClass()">Leave Your Feedback</a></li>
                            <li class="nav-item"><a routerLink="/subscribeNewsletter" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"(click)="toggleClass()">Our Newsletter</a></li>

                        </ul>
                    </li>
                    <li class="nav-item cursor" (click)="showPhase(6)" [class.phase-active]="activeButton === 6"><a routerLink="/venues" class="nav-link">Venues</a></li>

                    <li class="nav-item" (click)="showPhase(7)" [class.phase-active]="activeButton === 7"><a routerLink="/about-us" class="nav-link" (click)="toggleClass()">About Us<i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Our Story</a></li>
                            <li class="nav-item"><a href="https://indianaroma.co.uk/" class="nav-link" target="_blank" (click)="toggleClass()">Our Restaurant</a></li>
                            <!-- <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">FAQ</a></li> -->
                            <!--<li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Blog</a></li> -->
                            <li class="nav-item"><a href="https://www.blog-indianaroma.co.uk/" class="nav-link" target="_blank">Blog</a></li> 
                            <li class="nav-item"><a routerLink="/audit-and-certificates" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()"> Audits & Certificates       </a></li>
                            <li class="nav-item"><a routerLink="/scp" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()"> Social Policy & Responsibility      </a></li>
                        </ul>
                    </li>


                    <!-- <li
                        class="nav-item" (click)="showPhase(7)" [class.phase-active]="activeButton === 7"><a href="/contact" class="nav-link" target="_blank" >Contact Us</a>
                    </li> -->
                    <li class="nav-item cursor" (click)="showPhase(8)" [class.phase-active]="activeButton === 8"><a routerLink="/contact" class="nav-link test" (click)="toggleClass()"> Contact Us</a></li>
                </ul>
            </div>
            <div class="others-option d-flex align-items-center">
                <div class="option-item">
                    <a routerLink="/contact" class="default-btn"><i class="bx bx-phone-call"></i>Contact us<span></span></a>

                </div>
            </div>
        </nav>
    </div>
</div>
