<div class="categories-area pt-100 pb-50">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">It's time to decide on a menu and make a catering order.</span>
            <h2>Our exclusive range of Catering Combos and Menu Options</h2>
            <p>We can create a menu specifically for your event.</p>
        </div>
        <div class="courses-slides">
            <owl-carousel-o [options]="coursesSlides">
                <ng-template carouselSlide>
                    <div class="single-categories-box">
                        <img src="assets/img/about/abount3.png" alt="image">
                        <div class="content">
                            <h3>Best Selling Combos</h3>
                            <span>Catering Combos</span>
                        </div>
                        <a routerLink="/best-selling-combos" class="link-btn"></a>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-categories-box">
                        <img src="assets/img/about/abount3.png" alt="image">
                        <div class="content">
                            <h3>Breakfast Combos</h3>
                            <span>Catering Combos</span>
                        </div>
                        <a routerLink="/breakfast-combos" class="link-btn"></a>
                    </div>
                </ng-template>


                <ng-template carouselSlide>
                    <div class="single-categories-box">
                        <img src="assets/img/about/abount3.png" alt="image">
                        <div class="content">
                            <h3>Lunch/Dinner Combos</h3>
                            <span>Catering Combos</span>
                        </div>
                        <a routerLink="/lunch-or-dinner-combos" class="link-btn"></a>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-categories-box">
                        <img src="assets/img/about/abount3.png" alt="image">
                        <div class="content">
                            <h3>Live Stations Menu</h3>
                            <span>Menus</span>
                        </div>
                        <a routerLink="/live-stations" class="link-btn"></a>
                    </div>
                </ng-template>


                <ng-template carouselSlide>
                    <div class="single-categories-box">
                        <img src="assets/img/about/abount3.png" alt="image">
                        <div class="content">
                            <h3>Pure Veg Menu</h3>
                            <span>Menus</span>
                        </div>
                        <a routerLink="/pure-vegmenu" class="link-btn"></a>
                    </div>
                </ng-template>


                <ng-template carouselSlide>
                    <div class="single-categories-box">
                        <img src="assets/img/about/abount3.png" alt="image">
                        <div class="content">
                            <h3>Our Full Menu</h3>
                            <span>Menus</span>
                        </div>
                        <a routerLink="/our-full-menu" class="link-btn"></a>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-categories-box">
                        <img src="assets/img/about/abount3.png" alt="image">
                        <div class="content">
                            <h3>Wedding Combos</h3>
                            <span>Menus</span>
                        </div>
                        <a routerLink="/wedding-combos" class="link-btn"></a>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-categories-box">
                        <img src="assets/img/about/abount3.png" alt="image">
                        <div class="content">
                            <h3>Customise Menu</h3>
                            <span>Menus</span>
                        </div>
                        <a routerLink="/cutomize-menu" class="link-btn"></a>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-categories-box">
                        <img src="assets/img/about/abount3.png" alt="image">
                        <div class="content">
                            <h3>Canapés Menu</h3>
                            <span>Menus</span>
                        </div>
                        <a routerLink="/canapes-menu" class="link-btn"></a>
                    </div>
                </ng-template>


            </owl-carousel-o>
        </div>
        <div class="courses-info">
            <p>We can't wait to hear about your event... <a routerLink="/event-enquiry">Get a Free Quote today</a>.</p>
        </div>
    </div>
</div>
