import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

constructor(private http: HttpClient) { }

  getFeedbackData(){
    return this.http.get('https://266niqmz44.execute-api.eu-west-2.amazonaws.com/prod/reviews/festival-and-social-events-reviews');
  }

  getData(selectedValue?) {
    let url = 'https://vt8cp662fg.execute-api.eu-west-2.amazonaws.com/test/reviews/getreviews';
    if(selectedValue) url += `/${selectedValue}`;
    return this.http.get(url).pipe(
      map((response: any) => response.data)
    );
  }
}
