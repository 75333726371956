<div class="courses-area pt-100 pb-50">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">It's time to place catering order</span>
            <h2>Choice of Catering Services</h2>
            <p>We can tailor a menu to your specific requirements.</p>
        </div>
        <div class="courses-slides">
            <owl-carousel-o [options]="coursesSlides">
                <ng-template carouselSlide>
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a routerLink="/live-dosa-station" class="d-block image">
                                <img src="assets/img/courses/img1-iac.jpg" alt="image">
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow"><i class='flaticon-heart'></i></div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/pot-iac.png" class="rounded-circle" alt="image">
                                <span>Live Dosa</span>
                            </div>
                            <h3><a routerLink="/live-dosa-station">Live Stations</a></h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a routerLink="/family-gatherings" class="d-block image">
                                <img src="assets/img/courses/iac-29.png" alt="image">
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow"><i class='flaticon-heart'></i></div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/pot-iac.png" class="rounded-circle" alt="image">
                                <span>Breakfast Menu</span>
                            </div>
                            <h3><a routerLink="/family-gatherings">House Warming Party</a></h3>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a routerLink="/festival-and-events" class="d-block image">
                                <img src="assets/img/courses/iac-2.png" alt="image">
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow"><i class='flaticon-heart'></i></div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/pot-iac.png" class="rounded-circle" alt="image">
                                <span>Festival Event Menu</span>
                            </div>
                            <h3><a routerLink="/festival-and-events">Diwali Festival Event</a></h3>
                        </div>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a routerLink="/charity_fundraising_events" class="d-block image">
                                <img src="assets/img/catering-services/charity.png" alt="image">
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow"><i class='flaticon-heart'></i></div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/pot-iac.png" class="rounded-circle" alt="image">
                                <span>Our Stall at Charity Event</span>
                            </div>
                            <h3><a routerLink="/charity_fundraising_events">Charity Fundraising Events</a></h3>
                        </div>
                    </div>
                </ng-template>


                <ng-template carouselSlide>
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a routerLink="/film-crew-catering" class="d-block image">
                                <img src="assets/img/catering-services/film-catering.png" alt="image">
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow"><i class='flaticon-heart'></i></div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/pot-iac.png" class="rounded-circle" alt="image">
                                <span>Catering for Film Crews</span>
                            </div>
                            <h3><a routerLink="/film-crew-catering">Film Crew Catering</a></h3>
                        </div>
                    </div>
                </ng-template>



                <ng-template carouselSlide>
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <a routerLink="/family-gatherings" class="d-block image">
                                <img src="assets/img/catering-services/family-gatherings.png" alt="image">
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow"><i class='flaticon-heart'></i></div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="assets/img/pot-iac.png" class="rounded-circle" alt="image">
                                <span>House Parties/Family Occassions</span>
                            </div>
                            <h3><a routerLink="/family-gatherings">Family Gatherings</a></h3>
                        </div>
                    </div>
                </ng-template>



            </owl-carousel-o>
        </div>
        <div class="courses-info">
            <p>We can't wait to hear about your event... <a routerLink="/event-enquiry">Get a Free Quote today</a>.</p>
        </div>
    </div>
</div>
