import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thankyou-news-letter',
  templateUrl: './thankyou-news-letter.component.html',
  styleUrls: ['./thankyou-news-letter.component.scss']
})
export class ThankyouNewsLetterComponent implements OnInit {
  data1: any;

  constructor(private http:HttpClient,
    private route:ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.data1 = params.jwt;
    }
  );
  }

  ngOnInit(): void {

  }
}
