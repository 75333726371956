import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catering-services',
  templateUrl: './catering-services.component.html',
  styleUrls: ['./catering-services.component.scss']
})
export class CateringServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }



}
