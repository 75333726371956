<div class="container">
    <div class="section-title">
        <span class="sub-title">Testimonials</span>
        <h2>What People Say About Odemy</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>
    <div class="testimonials-slides">
        <owl-carousel-o [options]="testimonialsSlides">
            <ng-template carouselSlide *ngFor="let item of feedBackData">
                <div class="single-feedback-box">
                    <p>{{item.fields.comments}}</p>
                    <div class="client-info d-flex align-items-center">
                        <img src="assets/img/user1.png" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>{{item.fields.name}}</h3>
                            <span>{{item.fields.rating}} Reviews</span>
                            <ngb-rating [rate]="item.fields.rating" [max]="5" [readonly]=true>
                                <ng-template let-fill="fill">
                                  <span class="star" [ngClass]="'color'+item.fields.rating"[class.filled]="fill === 100">&#9733;</span>
                                </ng-template>
                            </ngb-rating>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
